import { Injectable } from '@angular/core';
import { ModelProvider } from '../models/general/model.provider';
import { AuthService } from '../../auth/auth.service';
import { User } from '../models/user.model';
import { WorkspaceService } from '../workspace/workspace.service';
import { ConfigPrivilege } from '../models/config.model';
import { first } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { WorkspaceMember } from '../models/workspace-member';

@Injectable({ providedIn: 'root' })
export class PrivilegeService {
  private privilegeConfig: ConfigPrivilege;
  // user: User;

  constructor(
    private modelProvider: ModelProvider,
    private workspaceService: WorkspaceService
  ) {
    // this.modelProvider.config.privilege$.subscribe(el => this.privilegeConfig = el);
    // this.auth.user$.subscribe(user => {
    //   this.user = user;
    // });
  }

  async hasAccess(feature: string, member?: WorkspaceMember): Promise<boolean> {
    // console.log('hasAccess 00');
    const activeWorkspace = await this.workspaceService.activatedWorkspace.pipe(first()).toPromise();
    // console.log('hasAccess 01');
    // const currentWorkspaceMember = activeWorkspace.members.items[this.modelProvider.user.meReference.id];
    
    const currentWorkspaceMember = member || await activeWorkspace.getMyMember();
    if (!this.privilegeConfig) {
      this.privilegeConfig = await this.modelProvider.config.privilege$.pipe(first()).toPromise();
    }

    // console.log('currentWorkspaceMember.level, feature', currentWorkspaceMember.level, feature);
    if (currentWorkspaceMember) {
      
      return this.privilegeConfig.hasLevelAccessToFeature(currentWorkspaceMember.level, feature);
      // return this.privilegeConfig.privilege[currentWorkspaceMember.level].includes(role);
    }

    return false;


    // // console.log('has access', role);
    // if (this.hasRoleToPrivilege('admin', role)) {
    //   return true;
    // }

    // if (this.hasRoleToPrivilege('editor', role)) {
    //   return true;
    // }

    // return await this.hasRoleToPrivilege('player', role);
  }

  // async hasRoleToPrivilege(privilege: string, role: string): Promise<boolean> {
  //   const activeWorkspace = await this.workspaceService.activatedWorkspace.pipe(first()).toPromise();
  //   const currentWorkspaceMember = activeWorkspace.members.items[this.modelProvider.user.meReference.id];

  //   if (currentWorkspaceMember) {
  //     return this.privilegeConfig.privilege[currentWorkspaceMember.level].includes(role);
  //   }

  //   return false;
  // }

}
