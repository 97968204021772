import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UserProfileContextMenuItems } from './user-profile-context-menu-items';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { EventManager } from '../event-manager/event-manager.service';
import { HeaderAnalyticsService } from './header.analytics';
import { PrivilegeService } from '../privilege/privilege.service';
import { PRIVILEGES } from '../privilege/privilege-roles';
import { environment } from 'src/environments/environment';
import { Organization } from '../models/organization.model';
import { Subscription, combineLatest } from 'rxjs';
import { OrganizationPlansComponent } from 'src/app/organization/organization-plans/organization-plans.component';
import { LoadingController, ModalController } from '@ionic/angular';
import { OrganizationService } from 'src/app/organization/organization.service';
import { WorkspaceService } from '../workspace/workspace.service';
import { ModelProvider } from '../models/general/model.provider';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() active: string;
  @Input() isGamePage = false;

  isSuperadmin = false;

  user: User;
  designPageViewPrivilege = PRIVILEGES.DESIGN_PAGE_VIEW;
  organizePageViewPrivilege = PRIVILEGES.ORGANIZE_PAGE_VIEW;
  workspaceEditPrivilege = PRIVILEGES.WORKSPACE_EDIT;

  organizations: Organization[]

  private subscriptions: Subscription[] = [];

  public environment;

  constructor(private router: Router,
    private translateService: TranslateService,
    public authService: AuthService,
    public analytics: HeaderAnalyticsService,
    public privilegeService: PrivilegeService,
    public modalController: ModalController,
    public workspaceService: WorkspaceService,
    public organizationService: OrganizationService,
    public modelProvider: ModelProvider,
    public loadingCtrl: LoadingController,
  ) {
    this.environment = environment;
  }

  async ngOnInit() {
    this.user = await this.authService.user$.pipe(first()).toPromise();
    const authUser = await this.authService.afAuth.user.pipe(first()).toPromise();
    const tokenResult = await authUser.getIdTokenResult();
    this.isSuperadmin = tokenResult.claims.superadmin;

    // console.log(this.user);
    const orgSub = this.organizationService.organizations$.subscribe(organizations => {
      this.organizations = organizations;
    });
    this.subscriptions.push(orgSub);
  }

  isPlayLandingPageVisible(): boolean {
    return localStorage.getItem('is-play-landing-page-visible') ?
      localStorage.getItem('is-play-landing-page-visible') === 'true' :
      false;
  }

  openProfile() {
    this.router.navigateByUrl('/user-profile');
  }

  openManageTeams() {
    this.router.navigateByUrl('/teams/settings');
  }

  openManageOrganizations(organization: Organization) {
    this.router.navigateByUrl('/organization/' + organization.id);
  }

  openHelpCenter() {
    window.open('https://intercom.help/gamoteca/en/')
  }

  public async openPlansDialog() {
    await this.router.navigate(['organization', 'create']);
    // const modal = await this.modalController.create({
    //   component: OrganizationPlansComponent,
    //   cssClass: 'organization-plan'
    // });
    // await modal.present();
  }

  private _organization: Organization;
  public async setOrganization(organization: Organization) {
    if (this._organization && this._organization.ref.path === organization.ref.path) { return; }

    const loading = await this.loadingCtrl.create({
      message: 'Changing team...',
    });
    await loading.present();
    try {
      this._organization = organization;

      // this.setOrganization(organization);
      const activeOrganization = organization;
      const workspaces = this.workspaceService.workspaces;
      let filteredWorkspaces = workspaces.filter(workspace => {
        // if(workspace.isPersonal) {
        //   return true;
        // }
  
        if (workspace.ownerOrganizationRef) {
          return workspace.ownerOrganizationRef.isEqual(activeOrganization.ref);
        } else {
          return activeOrganization.isOrphan();
        }
      });
  
      filteredWorkspaces = await this.modelProvider.asyncFilter(filteredWorkspaces, async (workspace) =>
        (await workspace.getMyMember()).level !== 'player'
      );
  
      // .filter(workspace => workspace.getMyMember().level !== 'player')
      filteredWorkspaces
        .sort((a, b) => {
          if (a.isPersonal && !b.isPersonal) {
            return -1;
          } else if (!a.isPersonal && b.isPersonal) {
            return 1;
          }
          //  else {
          //   return 0;
          // }
  
          if (a.myMember.joinedAt && !b.myMember.joinedAt) {
            return -1;
          } else if (!a.myMember.joinedAt && b.myMember.joinedAt) {
            return 1;
          } else if (a.myMember.joinedAt && b.myMember.joinedAt) {
            return a.myMember.joinedAt.toMillis() > b.myMember.joinedAt.toMillis() ? 1 : -1;
          }
  
          return 0;
        });
  
      if (filteredWorkspaces.length > 0) {
        this.workspaceService.setActiveWorkspace(filteredWorkspaces[0]);
      }
      await this.organizationService.setOrganization(organization);
      this.router.navigateByUrl('/design');
  
      return true;
    } catch (error) {
      throw error;
    } finally {
      await loading.dismiss();
    }

    await this.authService.changeOrganization(organization.ref);
  }

  async logout() {
    await this.authService.signOut();
    await this.router.navigateByUrl('/welcome');
    window.location.reload(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
