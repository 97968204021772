import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Organization } from 'src/app/core/models/organization.model';
import { OrganizationMember, OrganizationMemberLevel } from 'src/app/core/models/organization-members.model';
import { Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { OrganizationInviteModalComponent } from '../organization-invite-modal/organization-invite-modal.component';
import { OrganizationMemberDeleteModalComponent } from './organization-member-delete-modal/organization-member-delete-modal.component';
import { OrganizationMemberReactivateModalComponent } from './organization-member-reactivate-modal/organization-member-reactivate-modal.component';
import { OrganizationAnalyticsService } from '../organization.analytics.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'organization-members',
  templateUrl: './organization-members.component.html',
  styleUrls: ['./organization-members.component.scss'],
})
export class OrganizationMembersComponent implements OnInit {
  @Input()
  activeTab: 'members' | 'players';

  @Input()
  organization: Organization

  members: OrganizationMember[];

  processingMembers: OrganizationMember[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private modalController: ModalController,
    private organizationAnalytics: OrganizationAnalyticsService
  ) { }

  ngOnInit() {
    const membersSub = this.organization.members$.pipe(
      map(members => members.filter(member => this.activeTab === 'players' ? member.level === 'player' : member.level !== 'player') )
    ).subscribe(members => this.members = members);
    this.subscriptions.push(membersSub);
  }

  ngAfterViewInit(): void {
    this.organizationAnalytics.openPage('Manage / ' + this.activeTab.charAt(0).toUpperCase() + this.activeTab.slice(1));
  }

  ngOnChanges(changes: SimpleChanges): void {

    // changes.organization.currentValue
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  async openInviteMember() {
    const modal = await this.modalController.create({
      component: OrganizationInviteModalComponent,
      componentProps: {
        organization: this.organization
      },
      cssClass: [
        'auto-height',
        'sm-modal'
      ]
    });
    await modal.present();
  }

  async changeLevel(member: OrganizationMember, level: OrganizationMemberLevel) {
    this.processingMembers.push(member);
    try {
      await member.changeLevel(level);
    } catch (error) {
      console.error(error);
    } finally {
      this.processingMembers = this.processingMembers.filter(el => el !== member);
      // this.analytics.createChangeRoleLevelEvent(level);
    }
  }

  async showRemoveFromTeamConfirmationDialog(member: OrganizationMember) {
    // return new Promise<boolean>(async (resolve, reject) => {
      const modal = await this.modalController.create({
        component: OrganizationMemberDeleteModalComponent,
        componentProps: {
          member: member,
        },
        cssClass: [
          'auto-height',
          'sm-modal'
        ]
      });
      await modal.present();
    // });
  }

  async showAddUserToTeamConfirmationDialog(member: OrganizationMember) {
    const modal = await this.modalController.create({
      component: OrganizationMemberReactivateModalComponent,
      componentProps: {
        member: member,
      },
      cssClass: [
        'auto-height',
        'sm-modal'
      ]
    });
    await modal.present();
    // return new Promise<boolean>(async (resolve, reject) => {
    //   const modal = await this.modalController.create({
    //     component: TeamMemberReactivateConfirmationDialogComponent,
    //     componentProps: {},
    //     cssClass: [
    //       'auto-height',
    //       'sm-modal'
    //     ]
    //   });
    //   modal.onDidDismiss().then((dismissed) => {
    //     if (dismissed.data && dismissed.data.action) {
    //       if (dismissed.role === 'backdrop') { }
    //       switch (dismissed.data.action) {
    //         case 'cancel':
    //           break;

    //         case 'reactivate':
    //           for (const memberId in this.workspace.members.items) {
    //             if (memberId === userId) {
    //               const currentMember = this.workspace.members.items[memberId];
    //               currentMember.reactivateUserAsMember();
    //               this.workspace.save();
    //             }
    //           }
    //           resolve(true);
    //           break;

    //         default:
    //           break;
    //       }
    //     }
    //   });
    //   await modal.present();
    // });
  }

}
