<div class="ion-padding modal-content">
  <h2 class="modal-title">
    <span class="float-left">{{'play-modal/title' | translate}}</span>
    <mat-icon
    class="float-left"
    [popper]="dependencyInfoTextPopper"
    [popperTrigger]="'hover'"
    [popperBoundaries]="'body'"
    [popperAppendTo]="'body'"
    [popperPlacement]="'right'"
    [popperApplyClass]="'info-text-popper'"
    >help</mat-icon>
    <div class="clearfix"></div>
<popper-content popperApplyClass="popover-color"
          #dependencyInfoTextPopper>
<p class="m-0 p-0" [innerHtml]="'play-modal/title/info-text' | translate"></p>
</popper-content>
  </h2>
  <ng-container *ngIf="!script.isPublished">
    <p>{{'play-modal/first-publish/paraghraph-1' | translate}}</p>
    <ul class="pl-5">
      <li class="mb-1" [innerHTML]="'play-modal/first-publish/paraghraph-1/item-1' | translate"></li>
      <li class="mb-1" [innerHTML]="'play-modal/first-publish/paraghraph-1/item-2' | translate"></li>
      <li class="" [innerHTML]="'play-modal/first-publish/paraghraph-1/item-3' | translate"></li>
    </ul>
    <p>{{'play-modal/first-publish/paraghraph-2' | translate}}</p>
    <p class="mt-4 text-center">
      <ion-button (click)="publish()" [disabled]="processing === 'publish'" shape="round" color="primary">
        <ion-spinner *ngIf="processing === 'publish'" slot="start" name="lines-small"></ion-spinner>
        {{'play-modal/first-publish/call-to-action/label' | translate}}
      </ion-button>
    </p>
    <p *ngIf="error">
      <ion-text color="warning">{{error}}</ion-text>
    </p>
  </ng-container>

  <ng-container *ngIf="script.isPublished">
    <mat-icon (click)="close()" class="close">close</mat-icon>
    <section *ngIf="(script?.isPublicDiffers$ | async)" class="publish">
      <div class="row">
        <div class="col-9">
          <p class="info m-0 p-0">
            <!-- <i> -->
            <ng-container *ngIf="!(script.isPublicDiffers$ | async)">
              {{'play-modal/publish/published-sync' | translate: {dateString: (script.public.publishedAt.toDate() |
              dateShort)} }}
            </ng-container>
            <ng-container *ngIf="script.isPublicDiffers$ | async">
              <span color="warning">{{'play-modal/publish/published-unsync/difference' | translate: {dateString:
                (script.draft.updatedAt.toDate() | dateShort)} }}</span><br />
              <span class="text-muted">{{'play-modal/publish/published-unsync/publish-time' | translate: {dateString:
                (script.public.publishedAt.toDate() | dateShort)} }}</span>
            </ng-container>
            <!-- </i> -->
          </p>
        </div>
        <div class="col-3">
          <ion-button (click)="publish()" [disabled]="processing === 'publish'" shape="round" color="secondary">
            <ion-spinner *ngIf="processing === 'publish'" color="secondary" slot="start" name="lines-small">
            </ion-spinner>
            {{'play-modal/publish/call-to-action/label' | translate}}
          </ion-button>
        </div>
      </div>
      <p *ngIf="error">
        <ion-text color="warning">{{error}}</ion-text>
      </p>

    </section>

    <section class="play">
      <h5 class="my-0">{{'play-modal/organized-event/title' | translate}}</h5>
      <p>{{'play-modal/organized-event/paragraph-1' | translate}}</p>
      <p class="mt-4 text-center">
        <ion-button (click)="openOrganize()" shape="round" color="warning" class="m-0">
          {{'play-modal/organized-event/call-to-action/label' | translate}}</ion-button>
        <!-- <span class="mx-4 text-muted or">or</span> -->
      </p>

      <!-- <ion-button shape="round" color="warning">Create Game Session</ion-button>
      <p class="my-2 text-muted">or</p>
      <ion-button shape="round" color="primary">Copy invite URL</ion-button> -->
    </section>

    <section class="options">
      <h5 class="my-0">{{'play-modal/invite-event/title' | translate}}</h5>
      <p>{{'play-modal/invite-event/paragraph-1' | translate}}</p>
      <p class="mt-5 text-center">
        <ion-button (click)="copyInviteURL()" shape="round" color="primary" class="m-0">
          {{'play-modal/invite-event/call-to-action/label' | translate}}</ion-button>
      </p>
      <h6 class="my-0 mt-5">
        {{'play-modal/invite-event/auto-join-enabled/label' | translate}}
        <!-- [(ngModel)]="autoJoinEnabled" -->
        <!-- [checked]="script.public?.autoJoinEnabled" -->
        <ion-toggle class="float-right" color="primary" [(ngModel)]="autoJoinEnabled"
          (ngModelChange)="toggleRandomJoin($event)">
        </ion-toggle>
      </h6>
      <p>
        <span class="text-muted">
          {{ 'play-modal/invite-event/auto-join-enabled/description' | translate }}
        </span>
      </p>
    </section>
    <h6 class="my-0 mt-5">
      {{'play-modal/feed-post/label' | translate}}
      <ion-toggle class="float-right" color="primary" [(ngModel)]="feedPostEnabled"
        (ngModelChange)="toggleFeedPost($event)">
      </ion-toggle>
    </h6>
    <p>
      <span class="text-muted">
        {{ 'play-modal/feed-post/description' | translate }}
      </span>
    </p>
  </ng-container>
</div>