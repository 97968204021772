import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { User } from '../models/user.model';
import { Workspace, WorkspaceLevel } from '../models/workspace.model';
import { Storage } from '@ionic/storage';
import { EventManager } from '../event-manager/event-manager.service';
import { ModelProvider } from '../models/general/model.provider';
import { TranslateService } from '@ngx-translate/core';
import firebase from "firebase/app"
import { Router } from '@angular/router';
import { ReplaySubject, Subscription, combineLatest, of, Observable } from 'rxjs';
import { take, first, map, switchMap, tap } from 'rxjs/operators';
import { DocumentReference } from '@angular/fire/firestore';
import { OrganizationService } from 'src/app/organization/organization.service';
import { Organization } from '../models/organization.model';

@Injectable({ providedIn: 'root' })
export class WorkspaceService {

  public static DEFAULT_WORKSPACE_KEY = 'defaultWorkspacePath';

  initCall = true;
  user: User;
  activeWorkspace: Workspace;
  activatedWorkspace = new ReplaySubject<Workspace>(1);
  workspaces: Workspace[] = [];
  workspaces$ = new ReplaySubject<Workspace[]>(1);

  constructor(private auth: AuthService,
    private storage: Storage,
    public modelProvider: ModelProvider,
    private translateService: TranslateService,
    private organizationService: OrganizationService
  ) {
    this.activatedWorkspace.subscribe(async workspace => {
      // console.log('Active workspace: ', workspace)
      if (workspace) {
        const member = await workspace.getMyMember();
        if (member.id !== 'guest') {
          console.log('workspace not guest', workspace.title, member)
          await this.organizationService.setByWorkspace(workspace);
          await this.storage.set(WorkspaceService.DEFAULT_WORKSPACE_KEY, workspace.getReference().path);
        }
      }
    });

    let userWorkspacesSubscription: Subscription = null;

    this.auth.user$.subscribe(user => {
      if ((user && user.ref.path) === (this.user && this.user.ref.path)) { return; }
      this.user = user;
      if (user) {

        if (userWorkspacesSubscription) {
          userWorkspacesSubscription.unsubscribe();
        }

        console.log('KOA');

        userWorkspacesSubscription = this.user.workspaces$.subscribe(workspaces => {
          console.log('KOA2', workspaces);
          this.workspaces$.next(workspaces);
          this.workspaces = workspaces;

        });
        this.user.workspaces$.pipe(first()).subscribe(async workspaces => {
          await this.setActiveWorkspace();
        });
      }
    });
  }

  private _hasDesignerWorkspace$: Observable<boolean>;
  public hasDesignerWorkspace$() {
    if (this._hasDesignerWorkspace$) { return this._hasDesignerWorkspace$ };

    this._hasDesignerWorkspace$ = this.auth.user$.pipe(
      switchMap(
        user => user
          ? this.modelProvider.workspaceMember
            .findAllByUser$(this.modelProvider.user.meReference)
            .pipe(
              map(workspaceMembers => workspaceMembers.filter(member => member.level !== 'player'))
            )
          : of([])
      ),
      map(nonPlayerMembers => nonPlayerMembers.length > 0)
    )

    // this._hasDesignerWorkspace$ = this.modelProvider.workspaceMember
    //   .findAllByUser$(this.modelProvider.user.meReference)
    //   .pipe(
    //     map(workspaceMembers => workspaceMembers.filter(member => member.level !== 'player')),
    //     map(nonPlayerMembers => nonPlayerMembers.length > 0)
    //   )
    // this._hasDesignerWorkspace$ = this.auth.user$.pipe(
    //   switchMap(
    //     user => user
    //       ? user.workspaces$.pipe(map(workspaces => workspaces.filter(workspace => workspace.isGlobal !== true && workspace.id !== 'global-games')))
    //       : of([])
    //   ),
    //   tap(workspaces => console.log('has workspace', workspaces)),
    //   map(workspaces => workspaces.length > 0)
    // );
    return this._hasDesignerWorkspace$;
  }

  public async setActiveWorkspaceByRef(workspaceRef: DocumentReference) {
    const workspace = await this.modelProvider.workspace.findByRef(workspaceRef).pipe(first()).toPromise();
    this.setActiveWorkspace(workspace);
  }

  public async refresh() {
    const workspace = await this.activatedWorkspace.pipe(first()).toPromise();
    // To refresh members list
    await this.setActiveWorkspaceByRef(workspace.ref);
  }

  public async setActiveWorkspace(workspace?: Workspace) {
    // if (!workspace) { return; }
    // console.log('WORKSPACE: ',workspace && workspace.ref.path, !this.activeWorkspace);
    // this.storage.get(WorkspaceService.DEFAULT_WORKSPACE_KEY).then(id => {
    // const loadStoredWorkspace = id && !workspace;
    // const loadDefaultWorkspace = !id && !workspace;
    // if (loadStoredWorkspace) {
    //   workspace = this.getWorkspaceWithId(id);
    // }
    // else if (loadDefaultWorkspace) {
    //   workspace = this.getDefaultWorkspace();
    // }

    if (!workspace && !this.activeWorkspace) {
      workspace = await this.getDefaultWorkspace();
    }

    // console.log('workspace', workspace)

    if (workspace) {
      const timestamp = firebase.firestore.Timestamp.now();
      // const member = workspace.members.getByID(this.user.id);
      // if (!member.joinedAt) {
      //   member.joinedAt = timestamp;
      //   workspace.save();
      // }

      // if (this.user) {
      //   this.user.joinToWorkspace(workspace, timestamp);
      // }
    }


    // We want to emit the activated workspace on every page load that's why initCall property has been introduced
    // if (this.initCall/* || id !== workspace.id*/) {
    if (workspace) {
      this.activeWorkspace = workspace;
      this.activatedWorkspace.next(workspace);
    }

    // }

    // this.initCall = false;
    // });
  }

  public async getDefaultWorkspace() {
    const storedWorkspacePath: string = await this.storage.get(WorkspaceService.DEFAULT_WORKSPACE_KEY);
    const lastWorkspace = this.workspaces.find(workspace => workspace.ref.path === storedWorkspacePath);

    if (lastWorkspace) {
      return lastWorkspace;
    }

    return this.getPersonalWorkspace();
    // return this.hasOnlyPersonalWorkspace() ? this.getPersonalWorkspace() : this.getFirstNonPersonalWorkspace();
  }

  public hasOnlyPersonalWorkspace() {
    return this.workspaces.length === 1;
  }

  public getPersonalWorkspace() {
    const personalWorkspace = this.workspaces.filter(workspace => workspace.isPersonal)[0];
    if (personalWorkspace) {
      return personalWorkspace;
    }

    if (this.workspaces.length > 0) {
      this.workspaces[0];
    }
  }

  public getFirstNonPersonalWorkspace() {
    const workspaces = this.getNonPersonalWorkspaces();
    return workspaces.length > 0 ? workspaces[0] : null;
  }

  public getNonPersonalWorkspaces() {
    return this.workspaces.filter(workspace => !workspace.isPersonal);
  }

  public getWorkspaceWithId(id: string) {
    const filtered = this.workspaces.filter(workspace => workspace.getReference().id === id);
    return filtered.length > 0 ? filtered[0] : null;
  }

  public isPersonalWorkspace(workspace: Workspace): boolean {
    return workspace.getReference().id === this.getPersonalWorkspace().getReference().id;
  }

  public async fallbackToFirstNonPersonalWorkspace() {
    // this scenario suggested by Samu. it's probably not the best user experience
    // when we switch active workspaces dynamically, but we can fine tune it when looks too confusing
    if (!this.hasOnlyPersonalWorkspace()) {
      const active = await this.activatedWorkspace.pipe(take(1)).toPromise();
      if (this.isPersonalWorkspace(active)) {
        this.setActiveWorkspace(this.getFirstNonPersonalWorkspace());
      }
    }
  }

  async addNew(organization: Organization) {
    const isValidWorkspaceLimit = await organization.isValidWorkspaceLimit();
    if (!isValidWorkspaceLimit) {
      await this.organizationService.showWorkspaceLimitWarning();
      throw new Error('workspace-limit-reached');
      return;
    }

    const response = await this.modelProvider.functions.httpsCallable('workspaceCreate')({
      organizationPath: organization.ref.path,
      workspaceTitle: this.translateService.instant('workspace/title/new')
    }).pipe(first()).toPromise();

    if (response && response.workspacePath) {
      const workspace = await this.modelProvider.workspace.findByRef(response.workspacePath).pipe(first()).toPromise();
      return workspace;
    }

    return false;
  }

  private redirectBasedOnCurrentRoute() {
    // Todo: Use activated route URL and try to create a pattern mapping which defines the non workspace specific page to land on.
    // e.g: URL starts with /design/... redirect to /design
  }
}
