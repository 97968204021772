import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Play } from 'src/app/core/models/play.model';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { IonInfiniteScroll } from '@ionic/angular';
import { PlayListPageAnalyticsService } from './play-list-page.analytics';
import { Workspace } from 'src/app/core/models/workspace.model';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, ReplaySubject, combineLatest } from 'rxjs';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';
import { Script } from 'src/app/core/models/script.model';
import { Storage } from '@ionic/storage';

export const LOADING_LIMIT = 25;

@Component({
  selector: 'app-list',
  templateUrl: './play-list-page.component.html',
  styleUrls: ['./play-list-page.component.scss']
})
export class PlayListPageComponent implements OnInit, OnDestroy {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  public plays: Array<Play> = [];
  private lastVisible: any;
  // TODO: replace if the user management is ready
  public defaultAvatarUrl = 'https://firebasestorage.googleapis.com/v0/b/gamoteca-161414.appspot.com/o/users%2Ffacilitator%2Favatar.png?alt=media&token=5265ea4d-525a-4212-873c-96aa13c9b649';
  public workspace: Workspace;
  private subscriptions: Subscription[] = [];
  // public typeFilter: 'organized-game' | 'public-game' = 'organized-game';
  public typeFilter$ = new ReplaySubject<'organized-game' | 'public-game'>(1);

  public scripts: Script[] = [];


  public analyticsData;

  constructor(
    private modelProvider: ModelProvider,
    public analytics: PlayListPageAnalyticsService,
    private route: ActivatedRoute,
    private router: Router,
    public workspaceService: WorkspaceService,
    public storage: Storage
  ) {
  }

  async ionViewWillEnter() {
    this.scrollCompleted = false;
    this.analytics.setScreen();


  }

  async ngOnInit() {
    // if (this.route.snapshot.queryParamMap.has('workspace')) {
    //   this.workspace = await this.modelProvider.workspace
    //     .findByID(this.route.snapshot.queryParamMap.get('workspace'))
    //     .pipe(first()).toPromise();
    // } else {
    //   this.workspace = await this.modelProvider.workspace
    //     .findByRef(this.modelProvider.workspace.myPersonalReference)
    //     .pipe(first()).toPromise();
    // }
    let playsSubscription: Subscription = null;
    let filterSubscription: Subscription = null;
    let scriptSubscription: Subscription = null;

    const workspaceSubscription = this.workspaceService.activatedWorkspace.subscribe(async workspace => {
      this.typeFilter$.next('organized-game');
      this.workspace = workspace;

      if(this.infiniteScroll) { this.infiniteScroll.disabled = false; }
      this.scrollCompleted = true;
      this.scrollCompleted = false;

      const lastViewedWorkspacePath = await this.storage.get('session-list-workspace');
      if (lastViewedWorkspacePath === workspace.ref.path) {
        const lastViewedSessionType = await this.storage.get('session-list-type');
        if (lastViewedSessionType) {
          this.typeFilter$.next(lastViewedSessionType);
        }
      } else {
        await this.storage.set('session-list-workspace', this.workspace.ref.path);
      }

      if (playsSubscription) {
        playsSubscription.unsubscribe();
      }

      if (filterSubscription) {
        filterSubscription.unsubscribe();
      }

      if (scriptSubscription) {
        scriptSubscription.unsubscribe();
      }

      scriptSubscription =
        combineLatest(
          this.modelProvider.script.findAllByWorkspace(workspace),
          this.typeFilter$
        )
          .subscribe(([scripts, typeFilter]) => {
            this.scripts = scripts;

            // scripts.reduce((a, b) => a + (b.analytics.plays.total.initialized || 0), 0);
            // scripts.reduce((accumulator, current, index) => accumulator + current.analytics.plays.total.initialized);

            // let gamesPlayed;
            // let playersReached;
            // let gamesCompleted;

            // if(typeFilter === 'organized-game') {
            //   gamesPlayed = scripts.reduce((a, b) => a + (b.analytics.play.customSession.initialized || 0), 0);
            //   playersReached = scripts.reduce((a, b) => a + (b.analytics.player.customSession.reached || 0), 0);
            //   gamesCompleted = scripts.reduce((a, b) => a + (b.analytics.play.customSession.finished || 0), 0);
            // } else {
            //   gamesPlayed = scripts.reduce((a, b) => a + (b.analytics.play.inviteSession.initialized + b.analytics.play.randomSession.initialized || 0), 0);
            //   playersReached = scripts.reduce((a, b) => a + (b.analytics.player.inviteSession.reached + b.analytics.player.randomSession.reached || 0), 0);
            //   gamesCompleted = scripts.reduce((a, b) => a + (b.analytics.play.inviteSession.finished + b.analytics.play.randomSession.finished || 0), 0);
            // }

            // this.analyticsData = [
            //   {
            //     name: 'Games Played',
            //     value: gamesPlayed
            //   },
            //   {
            //     name: 'Players Reached',
            //     value: playersReached,
            //     extra: { format: 'currency' }
            //   },
            //   {
            //     name: 'Games Completed ('+ Math.round(gamesCompleted / gamesPlayed * 100)  + '%)',
            //     value: gamesCompleted,
            //     extra: { format: 'time' }
            //   },
            // ];
          });


      filterSubscription = this.typeFilter$.subscribe(playType => {
        // console.debug(this.workspace);
        // console.log('playType',playType);
        if(this.infiniteScroll) { this.infiniteScroll.disabled = false; }
        this.scrollCompleted = true;
        this.scrollCompleted = false;

        playsSubscription = this.modelProvider.play.findAllByWorkspace(
          this.workspace,
          ref => ref
            .where('type', '==', playType)
            .orderBy('createdAt', 'desc')
            .limit(LOADING_LIMIT)
        ).subscribe(plays => {
          // console.log('plays',plays);

          this.plays = plays;
          if (plays.length > 0) {
            this.lastVisible = plays[plays.length - 1].snapshot;
          }
        });
        this.subscriptions.push(playsSubscription);
      });

      this.subscriptions.push(filterSubscription);
    });
    this.subscriptions.push(workspaceSubscription);
  }

  async setTypeFilter(type: 'organized-game' | 'public-game') {
    await this.storage.set('session-list-type', type);
    this.typeFilter$.next(type);
  }

  getOpenURL(play) {
    // Public game
    if (play.type === 'public-game') {
      if (play.status == 'pre-start') {
        return null;
      } else {
        return ['/organize', play.id, 'live']
      }
    }

    // Organized game
    if (play.type === 'organized-game') {
      if (play.status == 'pre-start') {
        return ['/organize', play.id, 'edit'];
      } else {
        return ['/organize', play.id, 'live'];
      }
    }
  }

  openPlay(play: Play) {
    const url = this.getOpenURL(play);
    if (this.getOpenURL(play)) {
      this.router.navigate(url);
    }
  }

  public scrollCompleted = false;

  public onScroll(event): void {
    // console.log('on scroll');
    this.typeFilter$.pipe(first()).subscribe(playType => {
      const subscription = this.modelProvider.play.findAllByWorkspace(
        this.workspace,
        ref => ref
          .orderBy('createdAt', 'desc')
          .where('type', '==', playType)
          .startAfter(this.lastVisible)
          .limit(LOADING_LIMIT)
      ).subscribe(plays => {
        // console.log(plays.length, this.lastVisible.id)
        const lastPlay = plays[plays.length - 1];
        if (plays.length === 0) {
          this.lastVisible = null;
          this.infiniteScroll.disabled = true;
          this.scrollCompleted = true;
        } else {
          // this.scrollCompleted = true;
          this.lastVisible = lastPlay.snapshot;
          this.plays = this.plays.concat(plays);
          event.target.complete();
        }
      });
      this.subscriptions.push(subscription);
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
