import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ScriptVersion } from "src/app/core/models/script-version.model";
import { Router } from "@angular/router";
import { ModelProvider } from "src/app/core/models/general/model.provider";
import { TranslateService } from "@ngx-translate/core";
import { Script } from "src/app/core/models/script.model";
import { Workspace } from "src/app/core/models/workspace.model";
import { ScripListAnalyticsService } from "./script-list-analytics-service";
import { BehaviorSubject, combineLatest, ReplaySubject, Subscription } from "rxjs";
import { WorkspaceService } from "src/app/core/workspace/workspace.service";
import { first, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { OrganizationService } from "src/app/organization/organization.service";
import { IntercomService } from "src/app/core/intercom/intercom.service";
import { PrivilegeService } from "src/app/core/privilege/privilege.service";
import { PRIVILEGES } from "src/app/core/privilege/privilege-roles";
import { IonInfiniteScroll, ModalController } from "@ionic/angular";
import { ScriptEmptyListComponent } from "./empty-list/empty-list.component";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-script-list",
  templateUrl: "./script-list-page.component.html",
  styleUrls: ["./script-list-page.component.scss"],
})
export class ScriptListPageComponent implements OnInit {
  // public scriptsByWorkspace: Map<Workspace, Array<Script>> = new Map<Workspace, Array<Script>>();
  public allScript: Array<Script> = [];
  public scripts: Array<Script> = [];
  public workspace: Workspace = null;

  private subscriptions: Subscription[] = [];

  public processing = null;
  public readonly: boolean;
  public PRIVILEGES = PRIVILEGES;

  public isSuperadmin = false;

  // loadLimit = 10;
  loadLimit$ = new ReplaySubject<number>(1);

  constructor(
    private modelProvider: ModelProvider,
    private router: Router,
    public analytics: ScripListAnalyticsService,
    private translateService: TranslateService,
    public workspaceService: WorkspaceService,
    public organizationService: OrganizationService,
    public intercom: IntercomService,
    public priviledgeService: PrivilegeService,
    private modalController: ModalController,
    private authService: AuthService
  ) { }

  async ngOnInit() {
    this.analytics.setScreen();
    // let scriptSubscription: Subscription = null;
    this.processing = "loading";

    this.isSuperadmin = await this.authService.isSuperAdmin();

    const workspaceSubscription = this.workspaceService.activatedWorkspace
      .pipe(
        // tap(workspace => console.log('activated workspace', workspace)),
        tap((workspace) => (this.processing = "loading")),
        tap((workspace) => (this.workspace = workspace)),
        tap(workspace => {
          this.loadLimit$.next(10);
          this.scrollCompleted = false;
        }),
        switchMap((workspace) => combineLatest([
          workspace.scripts$.pipe(
            map((scripts) =>
              scripts.sort((a, b) => {
                return a.createdAt.toMillis() > b.createdAt.toMillis() ? -1 : 1;
              })
            ),
            tap(scripts => this.allScript = scripts),
            shareReplay(1)
          ),
          this.loadLimit$
        ])),
        map(([scripts, loadLimit]) => scripts.slice(0, loadLimit)),
        tap((scripts) => (this.processing = false))
      )
      .subscribe(async (scripts) => {
        (this.scripts = scripts),
          (this.readonly = !(await this.priviledgeService.hasAccess(
            PRIVILEGES.DESIGN_EDIT
          )));
      });

    // @ts-ignore
    window.isTourCancelled = true;
    this.subscriptions.push(workspaceSubscription);
  }

  ngOnDestory(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  async addNewScript(isTourCancelled = true) {
    // @ts-ignore
    window.isTourCancelled = isTourCancelled;
    this.processing = "new-script";
    this.analytics.createAddNewScriptClickedEvent(this.workspace);

    try {
      const isScriptLimitValid =
        await this.workspaceService.activeWorkspace.isValidScriptLimit();
      if (!isScriptLimitValid) {
        throw new Error("script-limit-invalid");
        return;
      }

      const response = await this.modelProvider.functions
        .httpsCallable("createScript")({
          workspacePath: this.workspace.ref.path,
        })
        .pipe(first())
        .toPromise();

      if (response.result === "ok") {
        await this.router.navigate([
          "design",
          this.modelProvider.fsDB.doc(response.scriptPath).ref.id,
          "task",
        ]);
        // console.log('NEW SCRIPT -  2')
      }
    } catch (error) {
      if (error.message === "script-limit-invalid") {
        const organization =
          await this.workspaceService.activeWorkspace.ownerOrganization$
            .pipe(first())
            .toPromise();
        await this.organizationService.showScriptLimitWarning(organization);
      }
    } finally {
      this.processing = null;
    }
  }

  async openAI() {
    await this.router.navigateByUrl('/ai/create-game');
  }

  async showEmpty() {
    const modal = await this.modalController.create({
      component: ScriptEmptyListComponent,
      componentProps: {
        listPage: this,
        // version: this.version
      },
      cssClass: "script-empty-list auto-height",
    });
    return await modal.present();
  }

  // async addNewScript2() {
  //   this.processing = 'new-script';
  //   this.analytics.createAddNewScriptClickedEvent(this.workspace);

  //   const isScriptLimitValid = await this.workspaceService.activeWorkspace.isValidScriptLimit();
  //   if (!isScriptLimitValid) {
  //     const organization = await this.workspaceService.activeWorkspace.ownerOrganization$.pipe(first()).toPromise();
  //     await this.organizationService.showScriptLimitWarning(organization);
  //     return;
  //   }

  //   const newScript: Script = this.modelProvider.script.create('scripts', {
  //     active: true,
  //     workspaceRef: this.workspace.ref
  //   });
  //   const newVersion: ScriptVersion = this.modelProvider.scriptVersion.create(newScript.getPath() + '/versions/draft', {
  //     title: this.translateService.instant('script-list/title/default'),
  //     description: '',
  //     image: {
  //       sizes: {
  //         original: {
  //           url: ''
  //         }
  //       }
  //     },
  //     teaser: {
  //       url: null
  //     },
  //     languages: {
  //       [this.modelProvider.localize.language]: true
  //     },
  //     active: true,
  //     isPublic: false
  //   });
  //   newVersion.isNew = true;
  //   try {
  //     await newScript.save();
  //     await this.modelProvider.script.findByRef(newScript.ref.path).pipe(first()).toPromise();
  //     await newVersion.save();
  //     await this.modelProvider.role.createDefaultRolesForVersion(newVersion);
  //     const version = await this.modelProvider.scriptVersion.findByRef(newVersion.ref.path).pipe(first()).toPromise();
  //     console.log(version);
  //     // await new Promise((resolve, reject) => {
  //     //   setTimeout(() => {resolve(true)}, 300)
  //     // });
  //     // console.log('NEW SCRIPT -  1')
  //     // await new Promise((resolve, reject) => setTimeout(() => resolve(true), 10000));
  //     await this.router.navigate([
  //       'design',
  //       newScript.id,
  //       'task'
  //     ]);
  //     // console.log('NEW SCRIPT -  2')
  //     this.processing = null;
  //   } catch (error) {
  //     this.processing = null;
  //   }
  // }

  trackById(index, item) {
    return item.id;
  }

  trackByKeyId(index, item) {
    return item.key.id;
  }

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  public scrollCompleted = false;
  async onScroll(event) {
    // console.log(event);
    let limit = (await this.loadLimit$.pipe(first()).toPromise());
    limit += 10;

    if (limit >= this.allScript.length) {
      this.scrollCompleted = true;
      event.target.disabled = true;
    }
    // await new Promise(resolve => setTimeout(resolve, 1500))
    // const scripts = this.allScript.slice(0, limit);
    // const promises = scripts.map(script => script.draft$.pipe(first()).toPromise());
    // await Promise.all(promises);
    event.target.complete();
    this.loadLimit$.next(limit);




  }
}
