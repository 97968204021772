<app-header></app-header>

<app-team-sidebar></app-team-sidebar>

<ion-content>
  <ng-template #personal>
    <app-team-personal></app-team-personal>
  </ng-template>
  <ng-container *ngIf="!workspaceService.isPersonalWorkspace(workspace); else personal">
    <h1 class="page-title-with-submenu">
      {{ 'manage-teams/title' | translate }}
    </h1>

    <div class="submenu">
      <div class="submenu-items">
        <div class="submenu-item"
            [routerLink]="'../settings'">
          {{ 'manage-teams/settings-title' | translate }}
        </div>
        <div class="submenu-item" [routerLink]="'../members'" [class.active-submenu]="page === 'members'">
          {{ 'manage-teams/members-title' | translate }}
        </div>
        <div class="submenu-item" [routerLink]="'../players'" [class.active-submenu]="page === 'players'">
          {{ 'manage-teams/players-title' | translate }}
        </div>
      </div>

      <div class="actions">
        <ion-button shape="round"
                    (click)="openInviteNewMemberDialog()"
                    [disabled]="!((workspaceEditPrivilege | privilege) | async)"
                    type="submit">
          <mat-icon>add</mat-icon>
          <ng-container *ngIf="page === 'members'">{{'manage-teams/invite-member/button' | translate}}</ng-container>
          <ng-container *ngIf="page === 'players'">{{'manage-teams/invite-player/button' | translate}}</ng-container>
        </ion-button>
      </div>
    </div>

    <content-container class="py-5" [back-button]="false">
      <div class="container py-3">
        <div class="row member-row"
            *ngFor="let member of members; let i = index">
          <ng-container *ngIf="(member.user$ | async); let user;">
            <div class="col-md-2 col-lg-1 vcenter member-picture"
                [ngStyle]="{'opacity': member.active ? 1 : 0.4}">
              <img [src]="user.photoURL"
                  alt="Member profile picture">
            </div>
            <div class="col-md-4 col-lg-5 vcenter name"
              [title]="user.email"
                [ngStyle]="{'opacity': member.active ? 1 : 0.4}">
              {{ user.displayName }}
              <ng-container *ngIf="!user.displayName">{{user.email}}</ng-container>
              <span *ngIf="!member.active">
                &nbsp;({{ 'manage-teams/remove-from-team/removed' | translate }})
              </span>
            </div>
            <div class="col-md-5 vcenter role"
                [ngStyle]="{'opacity': member.active ? 1 : 0.4}">
              <div class="progress-loader-wrapper">
                <ion-spinner *ngIf="processingMembers.includes(member)"
                            name="lines-small"></ion-spinner>
              </div>

              <form *ngIf="(workspaceEditPrivilege | privilege) | async">
                <select [ngModel]="member.level"
                        [name]="member"
                        [disabled]="processingMembers.includes(member) || !member.active"
                        (change)="changeLevel(member, $event.target.value)"
                        class="form-control">
                  <option [value]="'admin'">{{'manage-teams/role/admin' | translate }}</option>
                  <option [value]="'editor'">{{'manage-teams/role/editor' | translate }}</option>
                  <option [value]="'player'">{{'manage-teams/role/player' | translate }}</option>
                </select>
              </form>

              <div *ngIf="!((workspaceEditPrivilege | privilege) | async)">
                {{ member.level }}
              </div>
            </div>

            <div class="col-md-1 vcenter member-actions"
                *ngIf="(workspaceEditPrivilege | privilege) | async">
              <mat-icon *ngIf="member.active"
                        [title]="'manage-teams/remove-from-team/menu-item' | translate"
                        popperAppendTo="body"
                        (click)="showRemoveFromTeamConfirmationDialog(member)">checkbox</mat-icon>

              <mat-icon *ngIf="!member.active"
                        [title]="'manage-teams/add-to-team-again/menu-item' | translate"
                        (click)="showAddUserToTeamConfirmationDialog(member)">check_box_outline_blank</mat-icon>
            </div>
          </ng-container>
        </div>
      </div>
    </content-container>
  </ng-container>
</ion-content>
