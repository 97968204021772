import { Component, OnInit, Input } from '@angular/core';
import { Workspace, WorkspaceLevel } from 'src/app/core/models/workspace.model';
import { ModalController } from '@ionic/angular';
import { ManageTeamAnalyticsService } from 'src/app/teams/manage-teams/manage-team-analytics-service';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';
import { NgForm } from '@angular/forms';
import { Organization } from 'src/app/core/models/organization.model';
import { OrganizationMemberLevel } from 'src/app/core/models/organization-members.model';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-organization-invite-modal',
  templateUrl: './organization-invite-modal.component.html',
  styleUrls: ['./organization-invite-modal.component.scss'],
})
export class OrganizationInviteModalComponent implements OnInit {

  @Input()
  organization: Organization;

  email: string;
  memberRoleLevel: OrganizationMemberLevel = 'admin';
  isSendEmail = true;

  isSuperadmin = false;

  processing = false;

  constructor(
    private modalCtrl: ModalController,
    private authService: AuthService
    // public analytics: ManageTeamAnalyticsService,
    ) {
  }

  async ngOnInit() {
    this.isSuperadmin = await this.authService.isSuperAdmin();
    // this.analytics.createInviteMemberDialogOpenEvent();
  }

  onCancel(closeType: string) {
    // this.analytics.createInviteMemberDialogOnCancelClickedEvent(closeType);
    this.modalCtrl.dismiss({action: 'cancel'});
  }

  async onSubmit(inviteForm: NgForm) {
    if (inviteForm.valid) {
      this.processing = true;
      // this.analytics.createInviteMemberDialogOnSubmitClickedEvent();
      const result = await this.organization.inviteMember(this.email, this.memberRoleLevel, this.isSendEmail);
      this.processing = false;
      // console.log(result);
      this.modalCtrl.dismiss({
                               action: 'submit',
                               mail: this.email,
                               level: this.memberRoleLevel,
                               isSendEmail: this.isSendEmail
                             });
    }
  }

}
