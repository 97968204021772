import { Injectable, Injector } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from "firebase/app"
import { Observable } from 'rxjs/Observable';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { ReplaySubject, Subscription } from 'rxjs';
import { Facebook } from '@ionic-native/facebook/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { AnalyticsProvider } from '../core/analytics/analytics';
import { NavController, Platform } from '@ionic/angular';
import { User } from '../core/models/user.model';
import { ModelProvider } from '../core/models/general/model.provider';
import { environment } from 'src/environments/environment';
import { AngularFireFunctions } from '@angular/fire/functions';
import { concatMap, filter, first, map, shareReplay } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CloudMessagingService } from '../core/cloud-messaging/cloud-messaging.service';
import { OrganizationService } from '../organization/organization.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user: firebase.User;

  constructor(
    public afAuth: AngularFireAuth,
    public appVersion: AppVersion,
    private device: Device,
    private platform: Platform,
    private fsDB: AngularFirestore,
    private facebook: Facebook,
    private googlePlus: GooglePlus,
    private firebaseMessaging: FirebaseMessaging,
    public analytics: AnalyticsProvider,
    private modelProvider: ModelProvider,
    private fsFunction: AngularFireFunctions,
    private storage: Storage,
    private router: Router,
    private signInWithAppleService: SignInWithApple,
    private iab: InAppBrowser,
    private navCtrl: NavController,
    private cloudMessaging: CloudMessagingService,
    private injector: Injector,
    private translateService: TranslateService,
    // private organizationService: OrganizationService
    // private firebaseAnalytics: FirebaseAnalytics
  ) {
    afAuth.authState.subscribe(user => {
      this.user = user;
    });
  }

  private _user$: ReplaySubject<User>;
  public get user$(): ReplaySubject<User> {
    if (this._user$ !== undefined) { return this._user$; }
    this._user$ = new ReplaySubject<User>(1);

    let _userLoaded = undefined;

    this.afAuth.authState.subscribe(async fsUser => {
      if (_userLoaded !== undefined && (fsUser && fsUser.uid) === (_userLoaded && _userLoaded.uid)) { return; }

      // console.log(fsUser.);
      if (fsUser) {
        try {
          await this.user.getIdTokenResult(true).then(token => console.log(token));
        } catch (error) {
          console.error(error);
        }


        const organizationService = this.injector.get(OrganizationService);
        const organization =  await organizationService.getDefaultActiveOrganization();
        await organizationService.setOrganization(organization);
        // const organization =  await this.organizationService.getDefaultActiveOrganization();
        // await this.organizationService.setOrganization(organization);
  
        this.modelProvider.user.getMe$().subscribe(user => {
          _userLoaded = user;
          this._user$.next(user);
          this.isSuperAdmin().then()
          // console.log(user);
        });
      } else {
        _userLoaded = null;
        this._user$.next(null);
      }
    });
    return this._user$;
  }

  async signUp(credentials) {
    if (credentials.name.length < 1) {
      return Promise.reject({ code: 'auth/name-too-short' });
    }

    // Try to create user with email and password
    return this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then(async credential => {
        const user = await this.authState().pipe(filter(user => !!user), first()).toPromise();
        await user.updateProfile({
          displayName: credentials.name
        });
        await user.reload();

        return this.fsDB.collection('users').doc(credential.user.uid).set({
          email: credentials.email,
          displayName: credentials.name,
          company: credentials.company,
          newsletter: credentials.newsletter,
          language: ['en', 'es', 'fr', 'ar'].find(language => language === this.translateService.getBrowserLang()) || 'en',
          roles: {
            [environment.app]: {
              active: true,
              lastLoginAt: new Date(),
            }
          }
        }, { merge: true }).then(() => {
          this.analytics.setUser(user);
          this.analytics.setUserProperties({
            newsletter: credentials.newsletter,
            company: credentials.company,
            'display-name': credentials.name,
          });
          this.sendValidationEmail();
        });
      }).catch(async error => {
        // If the user is registered already that means it registred already with other provider (facebook, google, etc)
        // We send a verification email
        console.error(error);
        if (error.code === 'auth/email-already-in-use') {
          // Check if user is already registered with other provider(s)
          // If registered sign in with custom token and link email provider to this
          const callable = this.fsFunction.httpsCallable('authEmailAndPassword');
          const data = await callable({
            email: credentials.email,
            password: credentials.password,
            environment: environment,
            provider: 'email'
          }).toPromise();

          if (data.success === true && data.token) {
            await this.signInWithCustomToken(data.token);
            this.sendValidationEmail();
            const user = await this.authState().pipe(filter(user => !!user), first()).toPromise();
            await user.updateProfile({
              displayName: credentials.name
            });
            await user.reload();

            await this.fsDB.collection('users').doc(user.uid).set({
              displayName: credentials.name,
              company: credentials.company,
              newsletter: credentials.newsletter,
              roles: {
                [environment.app]: {
                  active: true,
                  lastLoginAt: new Date(),
                }
              }
            }, { merge: true });

            this.analytics.setUser(user);
            this.analytics.setUserProperties({
              newsletter: credentials.newsletter,
              company: credentials.company,
              'display-name': credentials.name,
            });

            return Promise.resolve(user);
          }

        }
        return Promise.reject(error);
      });

    // We need to link account to that


  }

  async signOut(): Promise<void> {
    // localStorage.clear();
    // await this.afAuth.signOut();
    // await this.fsDB.firestore.clearPersistence();
    // await this.storage.clear();
    console.log('signed out 0');

    if (this.platform.is('cordova')) {
      const user = await this.user$.pipe(first()).toPromise();
      delete user.device[this.device.uuid];
      // this.translate.use(user?.language || this.translate.getBrowserLang());
      await user.save();
      try {
        await this.googlePlus.logout();
      } catch (error) {
        console.error(error);
      }

      try {
        await this.facebook.logout();
      } catch (error) {
        console.error(error);
      }


      // await this.facebook.logout();
    }

    console.log('signed out 1');
    return this.afAuth.signOut().then(async (data) => {
      console.log('signed out 2');
      // this.afAuth.authState.
      await this.afAuth.authState.pipe(filter(user => !user), first()).toPromise();
      try {
        localStorage.clear();
      } catch (error) {
        console.error(error);
      }

      try {
        sessionStorage.clear();
      } catch (error) {
        console.error(error);
      }
      console.log('signed out 3');

      try {
        this.analytics.logout();
      } catch (error) {
        console.error(error);
      }

      const language = ['en', 'es', 'fr', 'ar'].find(language => language === this.translateService.getBrowserLang()) || 'en';
      await this.translateService.use(language).pipe(first()).toPromise();
      this.translateService.setDefaultLang(language || 'en');

      // console.log('signed out 4');
      // await this.removeHomeURL();
      console.log('signed out 5', language);
      try {
        await this.storage.clear();
        await this.storage.remove('redirectTo');
      } catch (error) {

      }

      // console.log('signed out', await this.storage.keys())
      return data;
    });
  }

  delete(): Promise<void> {
    return this.user.delete();
  }

  get authenticated(): boolean {
    return this.user !== null;
  }

  async getHomeURL() {
    const storedRedirectTo = await this.storage.get('redirectTo');

    if (storedRedirectTo) {
      return storedRedirectTo;
    }

    return environment.homeURL;
  }

  async removeHomeURL() {
    return await this.storage.remove('redirectTo');
  }

  async setHomeURL(url: string) {
    if (url !== environment.homeURL) {
      console.log('SET Home URL', url);
      return this.storage.set('redirectTo', url);
    }
  }

  async navigateToHome() {
    this.analytics.event('navigateToHome - 0');
    // console.log(await this.getHomeURL());
    this.analytics.event('navigateToHome - 1', null, { homeURL: await this.getHomeURL() });

    await this.navCtrl.navigateRoot(await this.getHomeURL());
    this.analytics.event('navigateToHome - 2');
    // await this.router.navigateByUrl(await this.getHomeURL());
    await this.storage.remove('redirectTo')
    this.analytics.event('navigateToHome - 3');
  }

  signInWithEmail(credentials) {
    console.log('Sign in with email');
    return this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password);
  }

  async changeOrganization(organizationRef: DocumentReference) {
    const callable = this.fsFunction.httpsCallable('authOrganizationChange');
    const data = await callable({
      organizationPath: organizationRef.path || null,
    }).pipe(first()).toPromise();

    if (data.success === true && data.token) {
      await this.signInWithCustomToken(data.token);
      const result = await this.user.getIdTokenResult(true);
      console.log('changeOrganization', result);
      return true;
    } else {
      throw data.error;
    }
  }

  async signInWithEmailAndPassword(email: string, password: string, organizationPath?: string) {
    const callable = this.fsFunction.httpsCallable('authEmailAndPassword');

    const data = await callable({
      email: email,
      password: password,
      organizationPath: organizationPath || null,
      provider: 'password'
    }).pipe(first()).toPromise();

    if (data.success === true && data.token) {
      return await this.signInWithCustomToken(data.token);
    } else {
      console.error(data);
      throw data.error;
    }

    // return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async singInWithEmailLink(email, href) {
    console.log('Sign in with email link');
    return firebase.auth().signInWithEmailLink(
      email,
      href
    );
  }

  async sendValidationEmail() {
    console.log('Send validation email');
    const callable = this.fsFunction.httpsCallable('sendVerificationEmail');
    const result$ = await callable({
      environment: environment,
      redirectUrl: await this.getHomeURL()
    }).pipe(first()).toPromise();
  }

  async signInWithFacebook() {
    try {
      // Mobile
      if (this.platform.is('cordova')) {
        const connectedResult = await this.facebook.getLoginStatus();
        let accessToken = null;
        if (connectedResult.status === 'connected') {
          accessToken = connectedResult.authResponse.accessToken;
        } else {
          const loginResult = await this.facebook.login(['email']);
          accessToken = loginResult &&
            loginResult.authResponse &&
            loginResult.authResponse.accessToken;
        }

        if (!accessToken) { throw new Error('Access rejected'); return; }

        // Check if user is already registered with other provider(s)
        // If registered sign in with custom token and link email provider to this
        const callable = this.fsFunction.httpsCallable('authFacebook');
        const data = await callable({
          accessToken: accessToken,
          environment: environment,
          provider: 'facebook'
        }).toPromise();

        if (data.success === true && data.token) {
          await this.signInWithCustomToken(data.token);
          // const credential = firebase.auth.EmailAuthProvider.credential(credentials.email, credentials.password);
          // this.afAuth.currentUser.linkWithCredential(credential);
          // this.afAuth.currentUser.
        }

        // return prevUser.linkWithCredential(credential);
        // this.afAuth.currentUser.linkWithCredential()

        // await this.authWithCredential(firebase.auth.FacebookAuthProvider.credential(accessToken));
        return await this.authState().pipe(filter(user => !!user), first()).toPromise();
      } else {
        // Desktop web
        const provider = new firebase.auth.FacebookAuthProvider();
        provider.addScope('email');
        await this.afAuth.signInWithPopup(provider)
          .catch(async error => {
            // console.error('facebook error: ', error, ['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code));
            if (['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code)) {
              const callable = this.fsFunction.httpsCallable('authFacebook');
              const data = await callable({
                accessToken: error.credential.accessToken,
                environment: environment,
                provider: 'facebook'
              }).toPromise();

              console.log('authFacebook: ', data);

              if (data.success === true && data.token) {
                await this.signInWithCustomToken(data.token);
                return await this.authState().pipe(filter(user => !!user), first()).toPromise();
              }

              Promise.reject(error);
            } else {
              Promise.reject(error);
            }
          });
        return await this.authState().pipe(filter(user => !!user), first()).toPromise();
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async signInWithGoogle() {
    // console.log('Sign in with google');
    try {
      // Mobile
      if (this.platform.is('cordova')) {
        const connectedResult = await this.googlePlus.trySilentLogin({}).catch(error => {
          console.log('trySilent', error);
          return error;
        });
        let accessToken = null;

        // console.log('google plugin silent accessToken: ',connectedResult.accessToken);

        if (connectedResult.accessToken) {
          accessToken = connectedResult.accessToken;
        } else {
          const loginResult = await this.googlePlus.login({
            webClientId: environment.auth.google.webClientID
          }).catch(error => {
            console.error('google login error: ');
            console.error(error);
            throw error;
          });
          accessToken = loginResult && loginResult.accessToken;
          // console.log('google plugin accessToken: ', accessToken);
        }

        if (!accessToken) { throw new Error('Access rejected'); return; }

        // Check if user is already registered with other provider(s)
        // If registered sign in with custom token and link email provider to this
        const callable = this.fsFunction.httpsCallable('authGoogle');
        const data = await callable({
          accessToken: accessToken,
          environment: environment,
          provider: 'google'
        }).toPromise();

        // console.log('authGoogle response', data);
        if (data.success === true && data.token) {
          await this.signInWithCustomToken(data.token);
        }

        // await this.authWithCredential(firebase.auth.FacebookAuthProvider.credential(accessToken));
        const user = await this.authState().pipe(filter(user => !!user), first()).toPromise();

        return user;
      } else {
        // Desktop web
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('email');
        await this.afAuth.signInWithPopup(provider)
          .then(userCredential => { console.log('userCredential', userCredential); return userCredential; })
          .catch(async error => {
            // console.error('facebook error: ', error, ['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code));
            if (['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code)) {
              const callable = this.fsFunction.httpsCallable('authGoogle');
              const data = await callable({
                accessToken: error.credential.accessToken,
                environment: environment,
                provider: 'google'
              }).toPromise();

              if (data.success === true && data.token) {
                await this.signInWithCustomToken(data.token);
                return await this.authState().pipe(filter(user => !!user), first()).toPromise();
              }

              return Promise.reject(error);
            } else {
              return Promise.reject(error);
            }
          });
        return await this.authState().pipe(first()).toPromise();
      }

    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async signInWithApple() {
    try {
      if (this.platform.is('cordova') && this.platform.is('ios')) {
        await this.signInWithAppleService.signin({
          requestedScopes: [
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
          ]
        }).then(async (res: AppleSignInResponse) => {
          if (res.identityToken) {
            const callable = this.fsFunction.httpsCallable('authApple');
            const data = await callable({
              res: res,
              accessToken: res.identityToken,
              environment: environment,
              provider: 'apple'
            }).toPromise();

            if (data.success === true && data.token) {
              await this.signInWithCustomToken(data.token);
            }

            const user = await this.authState().pipe(filter(user => !!user), first()).toPromise();
            return user;
          }
        })
      } else {

        // Desktop web
        const provider = new firebase.auth.OAuthProvider('apple.com')
        provider.addScope('email');
        await this.afAuth.signInWithPopup(provider)
          .catch(async error => {
            console.error('apple error: ', error, ['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code));
            if (['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code)) {
              const callable = this.fsFunction.httpsCallable('authApple');
              const data = await callable({
                accessToken: error.credential.accessToken,
                environment: environment,
                provider: 'apple'
              }).toPromise();

              console.log('authApple: ', data);

              if (data.success === true && data.token) {
                await this.signInWithCustomToken(data.token);
                return await this.authState().pipe(filter(user => !!user), first()).toPromise();
              }

              return Promise.reject(error);
            } else {
              return Promise.reject(error);
            }
          });
        return await this.authState().pipe(first()).toPromise();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async signInWithMoodle(domain: string) {
    const self = this;

    return new Promise(async (resolve, reject) => {
      let browser;
      const subscriptions: Subscription[] = [];

      const url = domain + '/local/oauth/login.php?client_id=gamoteca&response_type=code';

      const receiveMessage = async (event) => {
        if (self.platform.is('cordova')) {
          if (event.url.indexOf('.cloudfunctions.net/authMoodleRedirect') !== -1) {
            try {
              (browser as any).executeScript({ code: 'error' }, async (values) => {
                browser.close();
                subscriptions.forEach(sub => sub.unsubscribe);
                reject('auth/custom/access-denied');
              });
            } catch (error) {
              console.error(JSON.stringify(error));
            } finally {
              subscriptions.forEach(sub => sub.unsubscribe);
            }

            try {
              (browser as any).executeScript({ code: 'token' }, async (values) => {
                if (values[0]) {
                  await self.signInWithCustomToken(values[0]);
                  resolve(true);
                }

                browser.close();
              });
            } catch (error) {
              console.error(error);
              reject(error);
            } finally {
              subscriptions.forEach(sub => sub.unsubscribe);
            }

          }
        } else {
          if (event.data.method === 'loginWithCustomToken') {
            if (event.data.error) {
              reject('Invalid auth');
            } else {
              if (event.data.token) {
                await self.signInWithCustomToken(event.data.token);
                resolve(true);
              }
            }
          }
        }
      };

      // tslint:disable-next-line:max-line-length

      if (this.platform.is('cordova')) {
        browser = this.iab.create(url, '_blank', 'height=315,width=400');
        subscriptions.push(
          browser.on('loadstop').subscribe((event) => { receiveMessage(event) })
        );
        subscriptions.push(
          browser.on('message').subscribe((event) => { receiveMessage(event) })
        );
      } else {
        browser = window.open(url, '_blank', 'height=315,width=400');
        browser.removeEventListener('loadstop', receiveMessage);
        browser.addEventListener('loadstop', receiveMessage);
      }

      window.removeEventListener('message', receiveMessage);
      window.addEventListener('message', receiveMessage, false);
    });
  }

  public async signInWithKaya() {
    const self = this;

    return new Promise(async (resolve, reject) => {
      const receiveMessage = async (event) => {
        if (self.platform.is('cordova')) {
          if (event.url.indexOf('.cloudfunctions.net/redirect') !== -1) {
            try {
              (browser as any).executeScript({ code: 'error' }, async (values) => {
                browser.close();
                reject('auth/custom/access-denied');
              });
            } catch (error) {
              console.error(error);
            }

            try {
              (browser as any).executeScript({ code: 'token' }, async (values) => {
                if (values[0]) {
                  await self.signInWithCustomToken(values[0]);
                  resolve(true);
                }

                browser.close();
              });
            } catch (error) {
              console.error(error);
              reject(error);
            }

          }
        } else {
          if (event.data.method === 'loginWithCustomToken') {
            if (event.data.error) {
              reject('Invalid auth');
            } else {
              if (event.data.token) {
                await self.signInWithCustomToken(event.data.token);
                resolve(true);
              }
            }
          }
        }
      };

      // tslint:disable-next-line:max-line-length
      const url = 'https://kayaconnect.org/local/oauth/login.php?client_id=gamoteca&response_type=code';
      const browser = window.open(url, '_blank', 'height=315,width=400');
      browser.removeEventListener('loadstop', receiveMessage);
      browser.addEventListener('loadstop', receiveMessage);
      window.removeEventListener('message', receiveMessage);
      window.addEventListener('message', receiveMessage, false);
    });
  }


  signInWithCustomToken(token) {
    // console.log('Sign in with custom token', token);
    return this.afAuth.signInWithCustomToken(token);
  }

  // private authWithFBCredential(accessToken: string) {
  //     const facebookCredential = firebase.auth.FacebookAuthProvider.credential(accessToken);

  //     return firebase.auth().signInWithCredential(facebookCredential)
  //       .then((success) => {
  //           return success;
  //         // console.log('Firebase success: ' + JSON.stringify(success));
  //         // console.log(JSON.parse(JSON.stringify(success)));

  //         // this.storeUser(JSON.parse(JSON.stringify(success)));
  //         // this.navCtrl.push(MainPage);
  //       }).catch((error) => {
  //           return error;
  //         // console.log('Firebase failure: ' + JSON.stringify(error));
  //         // let toast = this.toastCtrl.create({
  //         //   message: 'error',//this.loginErrorString,
  //         //   duration: 3000,
  //         //   position: 'top'
  //         // });
  //         // toast.present();
  //       });
  //   }

  private authWithCredential(credential: firebase.auth.AuthCredential) {
    // const facebookCredential = firebase.auth.FacebookAuthProvider.credential(accessToken);

    return firebase.auth().signInWithCredential(credential)
      .then((success) => {
        return success;
        // console.log('Firebase success: ' + JSON.stringify(success));
        // console.log(JSON.parse(JSON.stringify(success)));

        // this.storeUser(JSON.parse(JSON.stringify(success)));
        // this.navCtrl.push(MainPage);
      }).catch((error) => {
        return error;
        // console.log('Firebase failure: ' + JSON.stringify(error));
        // let toast = this.toastCtrl.create({
        //   message: 'error',//this.loginErrorString,
        //   duration: 3000,
        //   position: 'top'
        // });
        // toast.present();
      });
  }

  private async oauthSignIn(provider: firebase.auth.AuthProvider) {
    const self = this;
    if (!this.platform.is('cordova')) {
      await this.afAuth.signInWithPopup(provider);
      return await this.authState().pipe(first()).toPromise();
    } else {
      if (provider instanceof firebase.auth.FacebookAuthProvider) {
        return self.facebook.getLoginStatus().then(async (response) => {
          if (response.status === 'connected') {
            await self.authWithCredential(firebase.auth.FacebookAuthProvider.credential(response.authResponse.accessToken));
            return await this.authState().pipe(first()).toPromise();
            // return self.authWithFBCredential(response.authResponse.accessToken)
          } else {
            return self.facebook.login(['email']).then(async (_response) => {
              await self.authWithCredential(firebase.auth.FacebookAuthProvider.credential(_response.authResponse.accessToken));
              return await this.authState().pipe(first()).toPromise();
              // return self.authWithFBCredential(_response.authResponse.accessToken)
            }).catch((_error) => {
              console.log('_error', _error);
            });
          }
        }).catch((error) => {
          console.log('error', error);
        });
      } else {
        return this.afAuth.signInWithRedirect(provider)
          .then(() => {
            return this.afAuth.getRedirectResult().then(async result => {
              // This gives you a Google Access Token.
              // You can use it to access the Google API.
              const token = (result.credential as any).accessToken;
              // The signed-in user info.
              const user = result.user;
              // console.log(token, user);
              return await this.authState().pipe(first()).toPromise();
            }).catch(function (error) {
              // Handle Errors here.
              alert(error.message);
            });
          });
      }
    }
  }

  public async isEmailVerified() {
    const user = await this.afAuth.authState.pipe(first()).toPromise();
    if(user.emailVerified) {
      return true;
    }

    return false;
  }

  // tslint:disable-next-line:member-ordering
  private _authState: Observable<firebase.User>;
  public authState(): Observable<firebase.User> {
    if (this._authState) {
      return this._authState;
    }

    // const self = this;
    // this._authState = new ReplaySubject<firebase.User>(1);

    this._authState = this.afAuth.authState.pipe(
      concatMap(async user => {
        if (user && user.uid) {
          return await this.storeUserToDB(user).then(async ready => {
            await this.analytics.setUser(user);
            await this.analytics.setUserProperties({
              'display-name': user.displayName,
              email: user.email,
              ['is-' + environment.app]: true,
              'user_id': user.uid
            });
            return user;
          }).catch(error => {
            // alert('storeUserToDB error ' + JSON.stringify(error))
            console.log('storeUserToDB error', JSON.stringify(error));
            return Promise.reject(error);
          });
        } else {
          // Logged out
          return user;
        }
      }),
      shareReplay(1)
    )

    return this._authState;

    // this.afAuth.authState.subscribe(user => {
    //   // Logged in
    //   if (user) {
    //     alert("1.0");
    //     this.analytics.setUser(user);
    //     this.analytics.setUserProperties({
    //       'display-name': user.displayName,
    //       email: user.email,
    //       ['is-' + environment.app]: true,
    //       'user_id': user.uid
    //     });
    //     // this.firebaseAnalytics.setUserId(user.uid);
    //     // this.firebaseAnalytics.setUserProperty('id', user.uid);
    //     alert("1.1");
    //     this.storeUserToDB(user).then(ready => {
    //       alert("1.2");
    //       this._authState.next(user);
    //     }).catch(error => {
    //       alert("1.3 error " + JSON.stringify(error));
    //       console.log('storeUserToDB error', JSON.stringify(error));
    //     });
    //   } else {  // Logged out
    //     this._authState.next(user);
    //     // this._authState = new ReplaySubject<firebase.User>(1);
    //     // this._authState.next(user);
    //   }
    // });

    return this._authState;
  }

  private updateUserOnLogin(authUser: firebase.User) {

  }

  private async storeUserToDB(authUser: firebase.User): Promise<any> {
    // const self = this;

    const fsDbUser = await this.fsDB.collection('users').doc(authUser.uid).get().toPromise();
    const fsDbUserData: any = fsDbUser.data() || {};

    const language = ['en', 'es', 'fr', 'ar'].find(language => language === this.translateService.getBrowserLang()) || 'en';
    // console.log('fsDbUserData',fsDbUserData);
    // authUser.
    const params: any = {
      ...fsDbUserData,
      displayName: fsDbUserData.displayName || authUser.displayName,
      email: fsDbUserData.email || authUser.email,
      photoURL: fsDbUserData.photoURL || authUser.photoURL,
      phoneNumber: fsDbUserData.phoneNumber || authUser.phoneNumber,
      language: fsDbUserData?.language || language,
      // createdAt: new Date(Number.parseInt(user.createdAt)),
      lastLoginAt: firebase.firestore.Timestamp.now(),
      roles: {
        [environment.app]: {
          active: true,
          lastLoginAt: firebase.firestore.Timestamp.now(),
        }
      }
      // organization: self.afs.collection('organizations').doc(organizationID).ref,
      // version: version,
    };

    if (!fsDbUser.exists || !fsDbUserData.roles || !fsDbUserData.roles[environment.app] || !fsDbUserData.roles[environment.app].registeredAt) {
      params.roles[environment.app].registeredAt = firebase.firestore.Timestamp.now();
    }

    if (authUser.displayName && !fsDbUserData.displayName) {
      params.displayName = authUser.displayName;
    }

    if (this.platform.is('cordova')) {
      this.fsFunction.httpsCallable('authSetIP')({ deviceID: this.device.uuid }).pipe().toPromise().then();
      params.device = {
        [this.device.uuid]: {
          platform: this.device.platform,
          manufacturer: this.device.manufacturer,
          model: this.device.model,
          serial: this.device.serial,
          uuid: this.device.uuid,
          version: this.device.version,
          appVersion: await this.getAppVersion(), // TODO,
          lastLoginAt: firebase.firestore.Timestamp.now(),
          pushNotificationToken: await this.firebaseMessaging.getToken(),
          roles: {
            [environment.app]: {
              active: true,
              lastLoginAt: firebase.firestore.Timestamp.now(),
            }
          }
        }
      };
    } else {
      this.fsFunction.httpsCallable('authSetIP')({ deviceID: 'web' }).pipe().toPromise().then();
      params.device = {
        web: {
          platform: navigator.platform,
          language: navigator.language,
          userAgent: navigator.userAgent,
          appVersion: await this.getAppVersion(), // TODO,
          lastLoginAt: firebase.firestore.Timestamp.now(),
          roles: {
            [environment.app]: {
              active: true,
              lastLoginAt: firebase.firestore.Timestamp.now(),
            }
          }
        }
      };
    }

    // Handle moodle params    
    const partnerProviderID = sessionStorage.getItem('partnerProviderID')
    const partnerEncryptedParams = sessionStorage.getItem('partnerEncryptedParams');
    if (partnerProviderID && partnerEncryptedParams) {
      // console.log('process moodle', partnerProviderID, partnerEncryptedParams);
      this.fsFunction.httpsCallable('addMoodleProvider')({
        providerID: partnerProviderID,
        encryptedParams: partnerEncryptedParams,
      }).pipe(first()).toPromise().then(async result => {
        // console.log('MOODLE RESULT', result);
        const tokenResult = await this.user.getIdTokenResult(true);
        // console.log(tokenResult);
        sessionStorage.removeItem('partnerProviderID');
        sessionStorage.removeItem('partnerEncryptedParams');
      });
    }



    return this.fsDB.collection('users').doc(authUser.uid).set(params, { merge: true });
  }

  public getDeviceID() {
    if (this.platform.is('cordova')) {
      return this.device.uuid;
    } else {
      return 'web';
    }
  }

  public async getAppVersion(): Promise<string> {
    if (this.platform.is('cordova')) {
      await this.platform.ready();
      return await this.appVersion.getVersionNumber().catch(error => { console.log(error); throw error; });
    } else {
      return await this.readConfig().then(config => config.appVersion);
    }
    // let self = this;
    // return new Promise<string>((resolve, reject) => {

    //     if (this.platform.is('cordova')) {
    //         self.appVersion.getVersionNumber().then(version => {
    //             resolve(version);
    //             // self.version = version;
    //         })
    //     } else {
    //         self.readConfig((success, fail) => {

    //         })
    //     }

    // });



  }


  // tslint:disable-next-line:member-ordering
  private cache = null;
  private readConfig(): Promise<any> {
    const self = this;
    return new Promise<string>((resolve, reject) => {
      if (this.cache === null) {
        const xhr = new XMLHttpRequest();
        xhr.addEventListener('load', function () {
          try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(xhr.responseText, 'application/xml');
            const widget = doc.getElementsByTagName('widget').item(0);

            self.cache = {
              appVersion: widget.getAttribute('version'),
              appName: widget.getElementsByTagName('name').item(0).textContent,
              packageName: widget.getAttribute('id'),
              versionCode: widget.getAttribute('browser-versionCode')
            };
            resolve(self.cache);
          } catch (e) {
            reject(e);
          }
        });

        xhr.addEventListener('error', function (e) {
          reject(e);
        });
        xhr.open('get', '../assets/config.xml', true);
        xhr.send();
      } else {
        setTimeout(function () {
          resolve(self.cache);
        }, 0);
      }
    });
  }

  async isSuperAdmin() {
    const fbUser = await this.authState().pipe(first()).toPromise();
    if (!fbUser) { return false };

    const tokenResult = await this.user.getIdTokenResult();
    // console.log(tokenResult);
    return tokenResult.claims.superadmin;
  }
}
