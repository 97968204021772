import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { PlayListPageComponent } from './play-list-page.component';
import { PlayComponentsModule } from '../play-components.module';
import { AppComponentsModule } from 'src/app/core/app-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../core/pipes/pipes.module';
import { PlayListPageAnalyticsService } from './play-list-page.analytics';
import { AuthGuard } from '../../auth/auth.guard';
import { PrivilegeGuard } from '../../core/privilege/privilege.guard';
import { PRIVILEGES } from '../../core/privilege/privilege-roles';
import { WorkspaceModule } from 'src/app/core/workspace/workspace.module';
import { MatIconModule } from '@angular/material/icon';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SafePipeModule } from 'safe-pipe';

const routes: Routes = [
  {
    path: '',
    component: PlayListPageComponent,
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.PLAY_LIST_VIEW}
  }
];

@NgModule({
            imports: [
              TranslateModule.forChild(),
              CommonModule,
              FormsModule,
              IonicModule,
              RouterModule.forChild(routes),
              PlayComponentsModule,
              AppComponentsModule,
              PipesModule,
              WorkspaceModule,//.forChild()
              MatIconModule,
              NgxChartsModule,
              SafePipeModule
            ],
  declarations: [PlayListPageComponent],
  providers: [PlayListPageAnalyticsService]
})
export class PlayListPageModule {}
