import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OrganizationPage } from './organization.page';
import { AuthGuard } from '../auth/auth.guard';
import { OrganizationPlansComponent } from './organization-plans/organization-plans.component';
import { OrganizationCreateComponent } from './organization-create/organization-create.component';
import { OrganizationEnterpriseComponent } from './organization-enterprise/organization-enterprise.component';
import { OrganizationRedirectGuard } from './organization-redirect.guard';
import { OrganizationCreateFreeComponent } from './organization-create-free/organization-create-free.component';

const routes: Routes = [
  {
    path: 'organization/create',
    component: OrganizationPlansComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'organization/create/free',
    component: OrganizationCreateFreeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'organization/contact/:id',
    component: OrganizationEnterpriseComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'organization/create/enterprise',
    redirectTo: 'organization/contact/enterprise',
  },
  // {
  //   path: 'organization/create/pro',
  //   redirectTo: 'organization/contact/pro',
  // },
  {
    path: 'organization/create/:id',
    component: OrganizationCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'organization/redirect/:organizationID',
    component: OrganizationPage,
    canActivate: [AuthGuard, OrganizationRedirectGuard]
  },
  {
    path: 'organization/:organizationID',
    component: OrganizationPage,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrganizationRoutingModule {}
