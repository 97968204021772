<app-header>
  <!-- <ion-title>{{'organization/title' | translate}}</ion-title> -->
</app-header>
<!-- <app-team-sidebar></app-team-sidebar> -->
<ion-content>
  <!-- <content-container [back-button]="false" #contentContainer> -->
  <div class="container pt-5">
    <form #form="ngForm" class="form" (ngSubmit)="submit()">
      <ion-slides #slides pager="false" [options]="slideOpts">
        <ion-slide>
          <div class="slide-content">
            <div class="row align-items-center">
              <div class="col-8">
                <div class="form-group w-100">
                  <p class="step" [innerHTML]="'onboarding/steps' | translate: {step: 1, steps: slideLength}">Step 1 of {{slideLength}}</p>
                  <h2 class="mb-0" [innerHTML]="'onboarding/team-name/title' | translate">What's the name of your company or team?</h2>
                  <p [innerHTML]="'onboarding/team-name/text' | translate">This will be the name of your Gamoteca team - choose something that your team will recognize.</p>
                  <!-- <label for="title">Team name</label> -->
                  <input id="play-title" #title="ngModel" name="title" [(ngModel)]="organization.title" class="px-1 w-50"
                    [placeholder]="'onboarding/team-name/placeholder' | translate" required />
                </div>
                <ion-button [disabled]="form?.controls?.title?.invalid" size="lg" shape="round"
                  (click)="nextStep()" [innerHTML]="'general/next' | translate">Next</ion-button>
              </div>
              <div class="col-4 pt-2">
                <img src="/assets/pages/designer-onboard/designer-onboard-1.jpg" alt="" />
              </div>
            </div>
          </div>
        </ion-slide>
        <ion-slide>
          <div class="slide-content">
            <div class="row align-items-center">
              <div class="col-8">
                <div class="form-group">
                  <p class="step" [innerHTML]="'onboarding/steps' | translate: {step: 2, steps: slideLength}">Step 2 of {{slideLength}}</p>
                  <!-- <h2 class="mb-0">What category would you say your team falls under?</h2> -->
                  <h2 class="mb-0" [innerHTML]="'onboarding/team-details/title' | translate">Tell us about your team</h2>
                  <p [innerHTML]="'onboarding/team-details/industry/title' | translate">What type of industry do you work in? </p>
                  <!-- <p>Is it technology, education, or something else?</p> -->
                  <!-- <label for="title">Category</label> -->
                  <select class="form-control w-50" name="category" [(ngModel)]="organization.category" required>
                    <option [value]="null" disabled selected [innerHTML]="'onboarding/team-details/industry/placeholder' | translate">Choose an industry</option>
                    <option *ngFor="let category of categories" [value]="category.value">{{category.label}}</option>
                  </select>
                  <!-- <input id="play-title" #title="ngModel" name="title" [(ngModel)]="organization.category" class="w-100" required /> -->
                </div>
                <div class="form-group mt-5">
                  <p [innerHTML]="'onboarding/team-details/size/title' | translate">How many people are on your team or work at your company?</p>
                  <select class="form-control w-50" name="size" [(ngModel)]="organization.size" required>
                    <option [value]="null" disabled selected [innerHTML]="'onboarding/team-details/size/placeholder' | translate">Select your team size</option>
                    <option *ngFor="let size of sizes" [value]="size">{{size}}</option>
                  </select>
                </div>
                <!-- <ion-button [disabled]="form?.controls?.category?.invalid" size="lg" shape="round"
                (click)="nextStep()">Next</ion-button> -->
                <ion-button [disabled]="form.invalid || processing" type="submit" size="lg" shape="round" color="warning">
                  <ion-spinner *ngIf="processing"></ion-spinner>
                  <span [innerHTML]="'onboarding/finish-button' | translate">Get Started</span>
                </ion-button>
              </div>
              <div class="col-4 pt-2">
                <img src="/assets/pages/designer-onboard/designer-onboard-3.jpg" alt="" />
              </div>
            </div>
          </div>
        </ion-slide>
        <ion-slide>
          <div class="slide-content">
            <div class="row align-items-center">
              <div class="col-8">
                <div class="form-group">
                  <p class="step">Step 3 of {{slideLength}}</p>
                  <h2 class="mb-0">How many people are on your team or work at your company?</h2>
                  <p>We want to make sure we have a good understanding of your team's context. This will help us tailor
                    our
                    support to your needs.</p>
                  <!-- <label for="title">Size</label> -->
                  <select class="form-control w-50" name="size" [(ngModel)]="organization.size" required>
                    <option [value]="null" disabled selected>Select your team size</option>
                    <option *ngFor="let size of sizes" [value]="size">{{size}}</option>
                  </select>
                </div>
                <ion-button [disabled]="form.invalid || processing" type="submit" size="lg" shape="round" color="warning">
                  <ion-spinner *ngIf="processing"></ion-spinner>
                  Get Started
                </ion-button>
              </div>
              <div class="col-4 pt-2">
                <img src="/assets/pages/designer-onboard/designer-onboard-3.jpg" alt="" />
              </div>
            </div>
          </div>
        </ion-slide>
      </ion-slides>
    </form>
  </div>
  <!-- </content-container> -->
</ion-content>