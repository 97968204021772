<app-header active="organize">
</app-header>

<app-team-sidebar></app-team-sidebar>

<ion-content>
  <div class="container py-3">
    <h2 *ngIf="workspace?.isPersonal">{{'workspace/personal/default-title' | translate}}</h2>
    <h2 *ngIf="!workspace?.isPersonal">{{workspace.title}}</h2>
    <ul class="tab p-0 m-0 mt-4 mb-5">
      <li (click)="setTypeFilter('organized-game')" class="d-inline-block" [class.active]="(typeFilter$ | async) === 'organized-game'">{{'play-list/tab/organized-game' | translate}}</li>
      <li (click)="setTypeFilter('public-game')"class="d-inline-block" [class.active]="(typeFilter$ | async)  === 'public-game'">{{'play-list/tab/public-game' | translate}}</li>
    </ul>
    <!-- ANALYTICS -->
    <div *ngIf="analyticsData" class="analytics">
      <ngx-charts-number-card
      class="chart-container"
      [view]="[995,150]"
      cardColor="#232837"
      emptyColor="#1e222e"
      [results]="analyticsData"
    >
    </ngx-charts-number-card>
    <div class="clearfix"></div>
    </div>

    <!-- TODO: remove ngIfs if the feature ready -->
    <div *ngIf="false" class="top row title-row">
      <div class="col-6">
        <h1 class="title"
            *ngIf="false">
          <span class="text">{{'play-list/page/title-personal' | translate}}</span>
        </h1>
      </div>
      <div class="col-6">
        <div class="actions"
             *ngIf="false">
          <ion-button shape="round"
                      type="submit">
            <mat-icon>add</mat-icon>
            {{'play-list/page/new-game-session' | translate}}
          </ion-button>
        </div>
      </div>
    </div>

      <div class="row event-card"
           *ngFor="let play of plays"
           [dir]="(play.scriptVersion$ | async)?.direction || 'ltr'"
           (click)="analytics.onPlayClick(play); openPlay(play);"
           [ngStyle]="{'cursor': getOpenURL(play) ? 'pointer': 'default'}">

        <div class="col-2 event-image"
             [ngStyle]="{'background-image': ('url('+ (play?.scriptVersion?._image | size : 'sm' : 'sm' : '/assets/images/img-placeholder.png') +')')  }">
        </div>

        <div class="col-4 play-title rtl-text">{{ play.title }}</div>

        <div class="col-2 created-on">
          <div class="avatars">
            <img [src]="defaultAvatarUrl"
                 [alt]="'general/created-on' | translate"/>
          </div>
          <div>{{ 'general/created-on' | translate }}</div>
          <div>{{ play.createdAt?.toDate() | date: 'dd/MM/YY' }}</div>
        </div>

        <div class="col-2 player-slots flex-column">
            <div class="d-flex align-items-center justify-content-center">
              <mat-icon>people</mat-icon>
              <span>{{ play.slots?.enrolled }}/{{ play.slots?.total }}</span>
            </div>
            <ion-badge *ngIf="play.accessLevel === 'private'" class="d-flex" size="small" color="warning">
              <ion-icon name="shuffle"></ion-icon>
              Random
            </ion-badge>
        </div>

        <div class="col-2 status text-center">
          <div class="label">
            {{ 'play-list/table/header/status/' + play.status | translate }}
          </div>
        </div>

      </div>

      <ion-infinite-scroll *ngIf="!scrollCompleted" threshold="1px"
                           (ionInfinite)="onScroll($event)">
        <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
      </ion-infinite-scroll>

  </div>
</ion-content>
