import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { User } from 'src/app/core/models/user.model';
import { Player } from 'src/app/core/models/player.model';
import { take, first, switchMap } from 'rxjs/operators';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Play } from 'src/app/core/models/play.model';
import { Script } from 'src/app/core/models/script.model';
import { ScriptVersion } from 'src/app/core/models/script-version.model';
import { Role } from 'src/app/core/models/role.model';
import { Team } from 'src/app/core/models/team.model';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MaskPipe, MaskService } from 'ngx-mask';
import { PlayEditAnalyticsService } from './play-edit-analytics.service';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-play-edit',
  templateUrl: './play-edit-page.component.html',
  styleUrls: ['./play-edit-page.component.scss'],
  providers: [
    MaskService
  ]
})
export class PlayEditPageComponent implements OnInit {
  private subscriptions = new Subscription();

  public play: Play;
  public script: Script;
  public scriptVersion: ScriptVersion;

  public roles: Role[];
  public teams: Team[];
  public players: Player[];

  public newMembers: Array<any> = [];

  public processing: string;

  public mask = 'AAA-AAA'

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modelProvider: ModelProvider,
    private ref: ChangeDetectorRef,
    private clipboard: ClipboardService,
    private toastController: ToastController,
    private translateService: TranslateService,
    private maskService: MaskService,
    private analyticsService: PlayEditAnalyticsService,
    private workspaceService: WorkspaceService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }

  async ionViewWillEnter() {
    const routeSnapshot = this.route.snapshot;
    const playID = routeSnapshot.paramMap.get('playID');
    // const routeData: { play: Play, script: Script } | any = this.route.data;
    // pipe(first()).toPromise();
    if (playID) {
      const play$ = this.modelProvider.play.findByRef('plays/' + playID);
      this.subscriptions.add(
        play$.subscribe(async play => {
          this.play = play;

          if (!this.play.share || !this.play.share.code) {
            this.play.share = {
              code: await this.getSharingCode()
            }
            await this.play.save();
          }
        })
      );
      play$.pipe(first()).subscribe(async play => {
        this.subscriptions.add(
          play.teams$.subscribe(teams => this.teams = teams)
        );
        this.subscriptions.add(
          play.players$.subscribe(players => this.players = players)
        );
        this.script = await play.script$.pipe(first()).toPromise();
        this.subscriptions.add(
          play.scriptVersion$.subscribe(version => this.scriptVersion = version)
        );
        await play.scriptVersion$.pipe(first()).toPromise();
        const roles$ = play.scriptVersion$.pipe(switchMap(version => version.roles$));
        this.subscriptions.add(
          roles$.subscribe(roles => this.roles = roles)
        );
        this.roles = await roles$.pipe(first()).toPromise();

        this.analyticsService.setScreen(play, this.script, this.scriptVersion);
      });
    } else {
      if (routeSnapshot.data.script) {
        this.script = routeSnapshot.data.script;
        this.scriptVersion = await this.script.publicVersion$.pipe(first()).toPromise();
        this.roles = await this.scriptVersion.roles$.pipe(first()).toPromise();
        
        const roleSlots: any = {};
        // tslint:disable-next-line: no-shadowed-variable
        this.roles.forEach(role => {
            roleSlots[role.id] = {
                enrolled: 0,
                total: 0,
                available: 0,
            }
        });

        this.play = this.modelProvider.play.create('plays', {
          title: this.scriptVersion.title,
          accessLevel: 'unlisted',
          scriptRef: this.script.ref,
          scriptVersionRef: this.scriptVersion.ref,
          gameMode: this.scriptVersion.gameMode,
          hasFacilitator: this.scriptVersion.hasFacilitator,
          startOn: 'manually',
          type: 'organized-game',
          slots: {
            enrolled: 0,
            total: 0,
            available: 0,
            roles: roleSlots
          },
          feed: {
            postEnabled: this.script?.public?.feed?.postEnabled || false
          },
          status: 'pre-start',
          workspaceRef: this.script.workspaceRef,
          organizationRef: this.workspaceService?.activeWorkspace?.ownerOrganizationRef || null
        });

        // console.log('this.play', this.play)

        this.play.share = {
          code: await this.getSharingCode()
        }


        this.teams = [];
        this.players = [];

        this.analyticsService.setScreen(this.play, this.script, this.scriptVersion);
      }
    }
    // this.route.data.subscribe(async (data: { play: Play, script: Script }) => {
    //   if (this.play) {
    //     this.play = data.play;
    //   } else {
    //     this.script = data.script;
    //     this.scriptVersion = await this.script.publicVersion$.pipe(first()).toPromise();
    //     this.play = this.modelProvider.play.create('plays', {
    //       title: this.scriptVersion.title
    //     });
    //   }
    // });
  }

  ionViewDidLeave() {
    this.subscriptions.unsubscribe();
  }

  private initListenToPlayers() {
    const play$ = this.modelProvider.play.findByRef('plays/' + this.play.id);
    this.subscriptions.add(
      play$.subscribe(play => {
        this.play = play;
      })
    );
    play$.pipe(first()).subscribe(async play => {
      this.subscriptions.add(
        play.teams$.subscribe(teams => this.teams = teams)
      );
      this.subscriptions.add(
        play.players$.subscribe(players => this.players = players)
      );
    });
  }

  onSubmit() {
    // this.formToTask().save();
    // this.submitted = true;
  }

  public cancel() {
    if(this.router.navigated) {
      this.location.back();
      return;
    }
    this.router.navigateByUrl('/organize');
  }

  public async save(close = true) {
    this.processing = 'save';
    this.analyticsService.saveClicked();
    // console.debug(this.modelProvider.user.meReference.path)
    // console.debug(this.play);
    await this.play.save();
    if (close) {
      // this.router.navigateByUrl('/organize');
      this.cancel();
    } else {
      this.initListenToPlayers();
    }
    this.processing = undefined;
  }

  public async start() {
    this.analyticsService.startClicked();
    this.processing = 'start';
    this.play.status = Play.STATUS_ONGOING;
    this.play.accessLevel = 'private';
    await this.play.save();
    await new Promise((resolve, reject) => setTimeout(resolve, 700));
    this.router.navigate(['organize', this.play.ref.id, 'live'], { replaceUrl: true });
    this.processing = undefined;
  }

  public hasFreeSlot() {
    if (this.teams.length === 0) {
      return true;
    }

    let found = false;
    for (let teamIndex = 0; teamIndex < this.teams.length; teamIndex++) {
      const team = this.teams[teamIndex];

      if (!this.roles) { return true; }

      for (let roleIndex = 0; roleIndex < this.roles.length; roleIndex++) {
        const role = this.roles[roleIndex];

        const foundPlayer = this.players.find(player => player.teamRef.path === team.ref.path && player.roleRef.path === role.ref.path);
        if (!foundPlayer) {
          return true;
        }
      }
    }

    return found;
    // this.teams.forEach(team => {
    //   this.roles.forEach(role => {
    //     // Player found
    //     found = found && this.players.(player => player.teamRef.path === team.ref.path && player.roleRef.path === role.ref.path);
    //   })
    // });

    return found;
  }

  private generateSharingCode(length): string {
    // const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const chars = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) { result += chars[Math.floor(Math.random() * chars.length)]; }
    return result;
  }

  private async getSharingCode(): Promise<string> {
    const code = this.generateSharingCode(6);
    const playExists = await this.play.findBySharingCode$(code).pipe(first()).toPromise();
    if (!playExists) {
      return code;
    }
    return await this.getSharingCode();
  }

  // public async shareURL() {
  //   // if (this.play) {
  //   //   const url = 'https://' + environment.dynamicLinkDomainPlayer + '/?link=' +
  //   //     environment.web.playUrl + '/playing/event/' + this.play.id +
  //   //     '&apn=' + environment.android.playPackageName + '&isi=' + environment.iOS.playAppId + '&ibi=' + environment.iOS.playBundleId;

  //   //   return url;
  //   // }
  // }

  public toggleShare(event: Event) {
    this.play.accessLevel = ((event.target as any).checked) ? 'unlisted' : 'private';
  }

  public async copyShareURL() {

    const workspace = await this.workspaceService.activatedWorkspace.pipe(first()).toPromise();
    let partnerParam = '';
    if (workspace.ownerOrganizationRef) {
      const organization = await workspace.ownerOrganization$.pipe(first()).toPromise();
      if (organization.integrations && organization.integrations.sso) {
        partnerParam = '?partner=' + organization.integrations.providerID;
      }
    }

    if (!partnerParam && workspace.partnership && workspace.partnership.name) {
      partnerParam = '?partner=' + workspace.partnership.name;
    }

    // const partnerParam = (workspace.partnership && workspace.partnership.name) ? '?partner=' + workspace.partnership.name : '';
    const url = 'https://' + environment.dynamicLinkDomainPlayer + '/?link=' +
      encodeURIComponent(environment.web.playUrl + '/playing/event/' + this.play.id + partnerParam) +
      '&apn=' + environment.android.playPackageName + '&isi=' + environment.iOS.playAppId + '&ibi=' + environment.iOS.playBundleId;


    this.clipboard.copyFromContent(url);

    const toast = await this.toastController.create({
      message: this.translateService.instant('script-list/menu/invite/url-copied'),
      duration: 2000
    });
    toast.present();
  }

  public async copyShareCode() {
    this.clipboard.copyFromContent(this.maskService.applyMask(this.play.share.code, this.mask));

    const toast = await this.toastController.create({
      message: this.translateService.instant('play-edit/form/members/share/copy-share-code/copied'),
      duration: 2000
    });
    toast.present();
  }

  async addMembers(emails: { display: string, value: string }[]) {
    // console.log("add members", emails)
    emails.forEach(email => {
      this.addMember(email.value);
    });
  }

  async addMember(email: string) {
    // console.log("addMember 0", email)
    const users = await this.modelProvider.user.findAllBy(ref => ref.where('email', '==', email)).pipe(take(1)).toPromise();
    // console.log("addMember 1", users)
    let user: User;
    if (users.length > 0) {
      user = users[0];
    } else {
      user = this.modelProvider.user.create('users', {
        displayName: email,
        email: email
      });
    }

    // console.log("addMember 0", user, email)

    if (this.players.find(player => player.userRef.isEqual(user.ref))) {
      return;
    }

    // this.modelProvider.player.playRef

    // console.log('teamREF', this.teams[0].ref)

    const player = this.modelProvider.player.create(this.play.ref.path + '/players', {
      playRef: this.play.ref,
      scriptRef: this.script.ref,
      scriptVersionRef: this.scriptVersion.ref,
      status: 'invited',
      teamRef: this.teams[0].ref,
      type: 'player',
      userRef: user.ref
    });
    // this.ref.detach();
    // const players = this.players;
    this.players = [].concat(this.players, player)
    // this.ref.detectChanges();
    // this.ref.markForCheck();
    // setTimeout(() => {}, 0);
    // console.log(this)
    console.log(this.players);
    // const scriptVersion = await this.play.scriptVersion$.pipe(take(1)).toPromise();
    // const member = this.modelProvider.player.create('members', {
    //   user: user.getDocument().ref,
    //   play: this.play.getDocument().ref,
    //   script: scriptVersion.getDocument().ref,
    //   status: Player.STATUS_INVITED,
    // });

    // console.log("addMember 1", user, email)

    // this.newMembers = [];
    // this.play.players.push(member);
    // console.log("this.play.members", this.play.players);
  }

  addTeam() {
    const newTeam = this.modelProvider.team.create(
      this.play.getPath() + '/teams',
      { title: 'New Team name' }
    );
    this.teams.push(newTeam);
  }

  toggleDisable(elem) {
    elem.className = (elem.className === 'editable active') ? 'editable' : 'editable active';
  }


}
