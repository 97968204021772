import { Injectable } from "@angular/core";
import { Organization } from "../core/models/organization.model";
import { Observable, ReplaySubject } from "rxjs";
import { ModelProvider } from "../core/models/general/model.provider";
import { switchMapTo, switchMap, first, map, tap } from "rxjs/operators";
import { Script } from "../core/models/script.model";
import { DocumentReference } from "@angular/fire/firestore";
import { Play } from "../core/models/play.model";
import { Workspace } from "../core/models/workspace.model";
import { ModalController } from "@ionic/angular";
import { UpgradeRequiredModalComponent } from "./upgrade-required-modal/upgrade-required-modal.component";
import { Router } from "@angular/router";
import { OrganizationJoinComponent } from "./organization-join/organization-join.component";
import { AuthService } from "../auth/auth.service";
import { Storage } from "@ionic/storage-angular";

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  // activeOrganization: Organization;
  activeOrganization$: ReplaySubject<Organization> =
    new ReplaySubject<Organization>(1);

  constructor(
    private modelProvider: ModelProvider,
    private modalController: ModalController,
    private router: Router,
    private auth: AuthService,
    private storage: Storage
  ) {}

  // public organizations: Organization[] = [];

  private _organizations$: Observable<Organization[]>;
  get organizations$() {
    if (this._organizations$) {
      return this._organizations$;
    }

    this._organizations$ = this.modelProvider.user.getMe$().pipe(
      switchMap((user) => user.organizationsPartOf$),
      map((organizations) =>
        organizations.sort((organization) => {
          return organization?.id === this.modelProvider.user.meReference.id
            ? -1
            : 1;
        })
      )
      // tap(organization => console.log('organization',organization))
    );
    // .pipe(
    //   map(organizations => {
    //     return [this.modelProvider.organization.getOrphanOrganization(), ...organizations];
    //   })
    // );

    // this.modelProvider.workspace.findAllByUser(this.modelProvider.user.meReference).pipe(
    //   switchMap(workspace => workspace.filter )
    // )

    return this._organizations$;
  }

  // getOrphanOrganization() {
  //   const model = this.modelProvider.organization.create(this.modelProvider.organization.orphanRef.path, {
  //     title: 'Legacy Team',
  //     plan: {
  //       name: 'free'
  //     }
  //   })

  //   return model;
  // }

  // private _organizationSet = false;
  private activeOrgazanition: Organization;
  async setOrganization(_organization: Organization) {
    const organization =
      _organization || this.modelProvider.organization.getOrphanOrganization();

    if (!organization.isOrphan()) {
      const plan = await organization.plan$.pipe(first()).toPromise();
      if ((window as any)?.mixpanel?.add_group) {
        (window as any).mixpanel.add_group("organization-id", organization.id);
        (window as any)?.mixpanel
          ?.get_group("organization-id", organization.id)
          .set(
            {
              $name: organization.title,
              // '$avatar': organization.imageUrl,
              $created: organization.createdAt.toDate(),
              "plan-id": plan.id,
              Plan: plan.title,
            },
            (result) =>
              // console.log(result)
              {}
          );
        // console.log((window as any).mixpanel.get_group('Organization Member', organization.id));
      }
    }

    if (
      this.activeOrgazanition &&
      this.activeOrgazanition.ref.path === organization.ref.path
    ) {
      return;
    }

    await this.setLatestStoredOrganization(organization);

    this.activeOrgazanition = organization;
    this.activeOrganization$.next(organization);
  }

  async getDefaultActiveOrganization() {
    const latestStoredOrganization = await this.getLatestStoredOrganization();
    if (latestStoredOrganization) {
      return latestStoredOrganization;
    }

    const organizationMembers =
      await this.modelProvider.organizationMember.findAllMy$
        .pipe(first())
        .toPromise();
    if (organizationMembers.length > 0) {
      const organization = await this.modelProvider.organization
        .findByRef(organizationMembers[0].organizationRef)
        .pipe(first())
        .toPromise();
      return organization;
    }

    return null;
  }

  static LATEST_ORGANIZATION_STORAGE_KEY = "lastActiveOrganizationID";
  async getLatestStoredOrganization() {
    const storedID: string = await this.storage.get(
      OrganizationService.LATEST_ORGANIZATION_STORAGE_KEY
    );
    if (storedID) {
      return await this.modelProvider.organization
        .findByID(storedID)
        .pipe(first())
        .toPromise();
    }
    return null;
  }
  async setLatestStoredOrganization(organization: Organization) {
    await this.storage.set(
      OrganizationService.LATEST_ORGANIZATION_STORAGE_KEY,
      organization.id
    );
  }

  async setByWorkspace(workspace: Workspace) {
    const workspaceMember = await workspace.getMyMember();
    if (workspaceMember.id === "guest") {
      console.log("setByWorkspace - guest");
      if (this.activeOrgazanition) {
        return;
      } else {
        await this.setOrganization(
          this.modelProvider.organization.getOrphanOrganization()
        );
        return;
      }
    }

    if (workspace.ownerOrganizationRef) {
      console.log(
        "setByWorkspace - workspace.ownerOrganizationRef",
        workspace.ownerOrganizationRef.path
      );

      const organizations = await this.organizations$.pipe(first()).toPromise();

      const organization = organizations.find((organization) =>
        organization.ref.isEqual(workspace.ownerOrganizationRef)
      );

      console.log("setByWorkspace - org", organizations, organization);
      if (organization) {
        await this.setOrganization(organization);
        return organization;
      }
    }

    console.log("setByWorkspace - null");

    await this.setOrganization(null);
  }

  async setByWorkspaceRef(workspaceRef: DocumentReference) {
    const workspace = await this.modelProvider.workspace
      .findByRef(workspaceRef)
      .pipe(first())
      .toPromise();
    return await this.setByWorkspace(workspace);
  }

  async setByScript(script: Script) {
    return await this.setByWorkspaceRef(script.workspaceRef);
  }

  async setByPlay(play: Play) {
    return await this.setByWorkspaceRef(play.workspaceRef);
  }

  async showWorkspaceLimitWarning(organization?: Organization) {
    const modal = await this.modalController.create({
      component: UpgradeRequiredModalComponent,
      cssClass: "upgrade-reqired auto-height",
      componentProps: {
        feature: "workspace",
        organization: organization,
      },
    });
    await modal.present();
  }

  async showScriptLimitWarning(organization?: Organization, title?: undefined) {
    const modal = await this.modalController.create({
      component: UpgradeRequiredModalComponent,
      cssClass: "upgrade-reqired auto-height",
      componentProps: {
        title: title,
        feature: "script",
        organization: organization,
      },
    });
    await modal.present();
  }

  async showDesignerLimitWarning() {
    const modal = await this.modalController.create({
      component: UpgradeRequiredModalComponent,
      cssClass: "upgrade-reqired auto-height",
      componentProps: {
        feature: "designer",
      },
    });
    await modal.present();
  }

  async showWarning(title: string) {
    const modal = await this.modalController.create({
      component: UpgradeRequiredModalComponent,
      cssClass: "upgrade-reqired auto-height",
      componentProps: {
        title: title,
      },
    });
    await modal.present();
  }

  async getAnalyticsParams() {
    const organization = await this.activeOrganization$
      .pipe(first())
      .toPromise();
    const additionalParams: any = {};
    if (!organization.isOrphan()) {
      additionalParams["organization-id"] = organization.id;
      additionalParams["organization-title"] = organization.title;

      const plan = await organization.plan$.pipe(first()).toPromise();
      additionalParams["plan-title"] = plan.title;
      additionalParams["plan-id"] = plan.id;
    }

    return additionalParams;
  }

  public lastActiveOrganizationRef() {}

  public async getOrganizationByScriptInfo(scriptPath: string) {
    const fsFunction =
      this.modelProvider.functions.httpsCallable("organizationGetBy");

    const result = (await fsFunction({
      scriptPath: scriptPath.trim(),
    })
      .pipe(first())
      .toPromise()) as {
      result: "ok" | "error";
      error?: string;
      script?: {
        id: string;
        path: string;
      };
      organization: {
        path: string;
        title: string;
        imageURL: string;
      };
      member?: {
        path: string;
        level: string;
      };
    };

    if (result.error) {
      throw new Error(result.error);
    }

    return result;
  }

  public async showPopupOrganizationByScriptInfo(result) {
    const loadImage = (url) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener("load", () => resolve(img));
        img.addEventListener("error", (err) => reject(err));
        img.src = url;
      });

    if (result.script) {
      await this.auth.setHomeURL(
        `playing/browse/design-details/${result.script.id}`
      );
    }

    if (result.member && result.script) {
      await this.auth.navigateToHome();
      // return result;
      // await this.router.navigateByUrl(`playing/event/${result.play.id}`);
    }

    // Not being part of the organization and the organization is found
    if (!result.member && result.organization) {
      if (result.organization.imageURL) {
        await loadImage(result.organization.imageURL).catch();
      }
      const modal = await this.modalController.create({
        component: OrganizationJoinComponent,
        cssClass: "organization-join auto-height",
        componentProps: {
          organization: result.organization,
        },
      });
      await modal.present();
      const data = await modal.onDidDismiss<boolean>();
      if (data.data === true) {
        return result;
      } else {
        throw new Error("closed");
      }
    }
  }

  /**
   *
   * @param scriptPath
   * @param handle Show popup for join. If false the function will return the result
   * @returns
   */
  public async getOrganizationByScript(scriptPath: string, handle = true) {
    const fsFunction =
      this.modelProvider.functions.httpsCallable("organizationGetBy");
    const result = (await fsFunction({
      scriptPath: scriptPath.trim(),
    })
      .pipe(first())
      .toPromise()) as {
      result: "ok" | "error";
      error?: string;
      script?: {
        id: string;
        path: string;
      };
      organization: {
        path: string;
        title: string;
        imageURL: string;
      };
      member?: {
        path: string;
        level: string;
      };
    };

    const loadImage = (url) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener("load", () => resolve(img));
        img.addEventListener("error", (err) => reject(err));
        img.src = url;
      });

    if (result.error) {
      throw new Error(result.error);
    }

    console.log(result);
    if (handle) {
      if (result.script) {
        await this.auth.setHomeURL(
          `playing/browse/design-details/${result.script.id}`
        );
      }
      if (result.member && result.script) {
        await this.auth.navigateToHome();
        // return result;
        // await this.router.navigateByUrl(`playing/event/${result.play.id}`);
      }

      if (!result.member && result.organization) {
        if (result.organization.imageURL) {
          await loadImage(result.organization.imageURL).catch();
        }
        const modal = await this.modalController.create({
          component: OrganizationJoinComponent,
          cssClass: "organization-join auto-height",
          componentProps: {
            organization: result.organization,
          },
        });
        await modal.present();
        const data = await modal.onDidDismiss<boolean>();
        if (data.data === true) {
          return result;
        } else {
          throw new Error("closed");
        }
      }
    }

    return result;
  }

  public async getOrganizationByPath(path: string, handle = true) {
    const fsFunction =
      this.modelProvider.functions.httpsCallable("organizationGetBy");
    const result = (await fsFunction({
      organizationPath: path.trim(),
    })
      .pipe(first())
      .toPromise()) as {
      result: "ok" | "error";
      error?: string;
      organization: {
        path: string;
        title: string;
        imageURL: string;
      };
      member?: {
        path: string;
        level: string;
      };
    };

    const loadImage = (url) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener("load", () => resolve(img));
        img.addEventListener("error", (err) => reject(err));
        img.src = url;
      });

    if (result.error) {
      throw new Error(result.error);
    }

    console.log(result);
    if (handle) {
      if (result.member) {
        await this.auth.navigateToHome();
        // return result;
        // await this.router.navigateByUrl(`playing/event/${result.play.id}`);
      }

      if (!result.member && result.organization) {
        if (result.organization.imageURL) {
          await loadImage(result.organization.imageURL).catch();
        }
        const modal = await this.modalController.create({
          component: OrganizationJoinComponent,
          cssClass: "organization-join auto-height",
          componentProps: {
            organization: result.organization,
          },
        });
        await modal.present();
        await modal.onDidDismiss();
      }
    }

    return result;
  }

  public async getOrganizationByCode(code: string, handle = true) {
    const fsFunction =
      this.modelProvider.functions.httpsCallable("organizationGetBy");
    const result = (await fsFunction({
      code: code.toLocaleUpperCase().trim(),
    })
      .pipe(first())
      .toPromise()) as {
      result: "ok" | "error";
      error?: string;
      play?: {
        id: string;
        path: string;
      };
      organization: {
        path: string;
        title: string;
        imageURL: string;
      };
      member?: {
        path: string;
        level: string;
      };
    };

    const loadImage = (url) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener("load", () => resolve(img));
        img.addEventListener("error", (err) => reject(err));
        img.src = url;
      });

    if (result.error) {
      throw new Error(result.error);
    }

    console.log(result);
    if (handle) {
      if (result.play) {
        await this.auth.setHomeURL(`playing/event/${result.play.id}`);
      }
      if (result.member && result.play) {
        await this.auth.navigateToHome();
        // return result;
        // await this.router.navigateByUrl(`playing/event/${result.play.id}`);
      }

      if (!result.member && result.organization) {
        if (result.organization.imageURL) {
          await loadImage(result.organization.imageURL).catch();
        }
        const modal = await this.modalController.create({
          component: OrganizationJoinComponent,
          cssClass: "organization-join auto-height",
          componentProps: {
            organization: result.organization,
          },
        });
        await modal.present();
        await modal.onDidDismiss();
      }
    }

    return result;
  }

  public async joinToOriganization(
    organizationRef: DocumentReference,
    handle = true
  ) {
    const fsFunction =
      this.modelProvider.functions.httpsCallable("organizationJoin");
    const response = (await fsFunction({
      organizationPath: organizationRef.path,
    })
      .pipe(first())
      .toPromise()) as {
      workspaces: Array<{
        path: string;
        id: string;
        imageURL: string;
        title: string;
        member: {
          path: string;
          id: string;
          level: string;
        };
      }>;
    };

    if (handle) {
      const user = await this.modelProvider.user.getMe();
      console.log(user);
      const member = await this.modelProvider.organizationMember
        .findByRef(organizationRef.collection("members").doc(user.id))
        .pipe(first())
        .toPromise();
      console.log(member);
      if (member) {
        await this.auth.changeOrganization(organizationRef);
        const organization = await this.modelProvider.organization
          .findByRef(organizationRef)
          .pipe(first())
          .toPromise();
        await this.setOrganization(organization);
        await this.auth.navigateToHome();
      }
    }

    return response;
  }

  // public async getMyOrganizations() {
  //   const fsFunction = this.modelProvider.functions.httpsCallable('getMyOrganizations');
  //   return await fsFunction({
  //   }).pipe(first()).toPromise() as {
  //     organizations: Array<
  //       {
  //         path: string,
  //         id: string,
  //         imageURL: string,
  //         title: string,
  //         member: {
  //           path: string,
  //           id: string,
  //           level: string
  //         }
  //       }
  //     >
  //   }
  // }

  // public async getOrganizationsByEmail(email: string) {
  //   const fsFunction = this.modelProvider.functions.httpsCallable('getOrganizationsByEmail');
  //   return await fsFunction({
  //     email: email,
  //   }).pipe(first()).toPromise() as {
  //     organizations: Array<
  //       {
  //         path: string,
  //         id: string,
  //         imageURL: string,
  //         title: string,
  //       }
  //     >
  //   }
  // }

  public async getDefaultOrganization() {
    return this.modelProvider.organization
      .findByRef(this.defaultOrganizationRef)
      .pipe(first())
      .toPromise();
  }

  public get defaultOrganizationRef() {
    return this.modelProvider.fsDB.collection("organizations").doc("").ref;
  }
}
