import { FirestoreModel } from '../firebase/firestore-model';
import { Observable, ReplaySubject, of, combineLatest, forkJoin } from 'rxjs';
import { Script } from './script.model';
import { DocumentReference, QueryFn } from '@angular/fire/firestore';
import { Model } from './general/model';
import { WorkspaceMember } from './workspace-member';
import { FirestoreMapField } from '../firebase/firestore-map-field';
import { first, flatMap, map, mergeAll, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Organization } from './organization.model';
import { ImageWithSizes } from './general/image-with-sizes';

export type WorkspaceLevel = 'player' | 'editor' | 'admin' | 'owner';

export class Workspace extends FirestoreModel<Workspace> {
  public COLLECTION_NAME = 'workspaces';

  public title: string;
  _image: ImageWithSizes;
  public get imageUrl() {
    return this._image ? this._image.getSizedUrl('576x') : this.photoURL;
  }
  public set imageUrl(url: string) {
    this._image.setSizedUrl(url);
  }
  public photoURL: string;
  public isPersonal: boolean;
  public isGlobal: boolean;
  // public members: FirestoreMapField<WorkspaceMember>;
  public partnership: {
    name: string;
  };
  public ownerOrganizationRef: DocumentReference;
  // public createdAt: Date;

  protected rules() {
    return {
      title: {},
      photoURL: {},
      _image: {
        [Model.RULE_ALIAS]: 'image',
        [Model.RULE_CLASS]: ImageWithSizes,
        [Model.RULE_DEFAULT]: () => new ImageWithSizes({}, this, {}, this.modelProvider)
      },
      isPersonal: {},
      isGlobal: {},
      // members: {
      //   [Model.RULE_CLASS]: FirestoreMapField,
      //   [Model.RULE_MAP_TO_CLASS]: WorkspaceMember,
      //   [Model.RULE_DEFAULT]: new FirestoreMapField<WorkspaceMember>([], this, {}, this.modelProvider)
      // },
      partnership: {
        [Model.RULE_DEFAULT]: {}
      },
      ownerOrganizationRef: {},
      // createdAt: {}
    };
  }

  /**
   * Script
   */
  // tslint:disable-next-line: member-ordering
  private _scripts$: ReplaySubject<Script[]>;
  public get scripts$(): Observable<Script[]> {
    if (this._scripts$ !== undefined) { return this._scripts$; }

    this._scripts$ = new ReplaySubject<Script[]>(1);
    this.modelProvider.script.findAllByWorkspace(this).subscribe(this._scripts$);
    return this._scripts$;
  }

  // tslint:disable-next-line: member-ordering
  private _scripts: Array<Script>;
  public get scripts(): Array<Script> {
    if (this._scripts !== undefined) { return this._scripts; }
    this._scripts = [];

    this.scripts$.subscribe(scripts => {
      this._scripts = scripts;
    });
    return this._scripts;
  }

  private _ownerOrganization$: ReplaySubject<Organization>;
  public get ownerOrganization$() {
    if (this._ownerOrganization$ !== undefined) { return this._ownerOrganization$; }

    if (!this.ownerOrganizationRef) { return of(this.modelProvider.organization.getOrphanOrganization()).pipe(shareReplay(1)) }

    this._ownerOrganization$ = new ReplaySubject<Organization>(1);
    this.modelProvider.organization.findByRef(this.ownerOrganizationRef).subscribe(this._ownerOrganization$);
    return this._ownerOrganization$;
  }

  public getScriptsBy(query: QueryFn) {
    return this.modelProvider.script.findAllByWorkspace(this, query);
  }

  public getPlaysBy(query: QueryFn) {
    return this.modelProvider.play.findAllByWorkspace(this, query);
  }

  public get myPersonalReference() {
    return this.modelProvider.fsDB
      .collection('workspaces')
      .doc(this.modelProvider.user.meReference.id).ref;
  }

  private _members$: Observable<WorkspaceMember[]>;
  public get members$() {
    if (this._members$) { return this._members$ }

    this._members$ = this.modelProvider.workspaceMember.findAllByWorkspace(this);
    return this._members$;
  }

  public async getMemberByUser(userRef: DocumentReference) {
    return await this.modelProvider.workspaceMember.findByRef(this.ref.collection('wsMembers').doc(userRef.id)).pipe(first()).toPromise();
    // return this.members.getByID(userRef.id);
  }

  public async getGuest() {
    return await this.modelProvider.workspaceMember.findByRef(this.ref.collection('wsMembers').doc('guest')).pipe(first()).toPromise();
  }

  public async getLevelByUser(userRef: DocumentReference) {
    return (await this.getMemberByUser(userRef)).level
    // return (await this.getMemberByUser(userRef)).level;
  }

  public async getMyMember() {
    if(this._myMember) { return this._myMember }
    this._myMember = await this.getMemberByUser(this.modelProvider.user.meReference);
    if (!this._myMember) {
      this._myMember = await this.getGuest();
    }
    // console.log(this._myMember);
    return this._myMember;
  }

  private _myMember: WorkspaceMember;
  public get myMember() {
    return this._myMember;
  }


  public inviteMember(email: string, level: WorkspaceLevel) {
    return this.modelProvider.functions.httpsCallable('changePrivilige')({
      email: email,
      level: level,
      workspacePath: this.ref.path
    });
  }

  public join(workspaceID?: string) {
    if (!workspaceID) {
      workspaceID = this.ref.id;
    }
    return this.modelProvider.functions.httpsCallable('workspaceJoin')({
      workspaceID: workspaceID
    }).pipe(first()).toPromise();
  }

  public findAllByUser(userRef: DocumentReference) {
    // const limit = ref2 => ref2.limit(5);
    // const mine = ref => ref.where('members.' + user.id + '.active', '==', true);

    // const query: Query = new Query();
    // firestore.Query
    // query.
    // const addToQuery = (ref: CollectionReference, queryFn: QueryFn) => {
    //     return queryFn(ref).where('members.' + user.id + '.active', '==', true);
    //     // const f = queryFn(ref).where('members.' + user.id + '.active', '==', true);
    //     // console.log(f);
    //     // return f;
    // };

    // const f: QueryFn = (ref => ref.where('members.' + user.id + '.active', '==', true))();

    // let queryFunction: QueryFn;
    // if(queryFn) {
    //     queryFunction = queryFn(this.modelProvider.fsDB.collection(this.COLLECTION_NAME).ref).where('members.' + user.id + '.active',
    // '==', true); } else { queryFunction = ref => ref.where('members.' + user.id + '.active', '==', true); } let queryFn: QueryFn; if
    // (query) { queryFn = ref => query.where('members.' + user.id + '.active', '==', true) } else { queryFn = ref => ref.where('members.'
    // + user.id + '.active', '==', true); }

    // _queryFn().where('members.' + user.id + '.active', '==', true);
    // const queryFn = QueryFn();
    // if (!query) {
    //     queryFn = ref => query.where('members.' + user.id + '.active', '==', true);
    // } else {

    //     queryFn().where('members.' + user.id + '.active', '==', true);
    // }
    // const _queryFn = queryFn().where('members.' + user.id + '.active', '==', true);
    // return this.findAllBy(
    //     (_queryFn)
    //         ? ref => { return addToQuery(ref, _queryFn).limit(3) }
    //         : ref => ref.where('members.' + user.id + '.active', '==', true).limit(3)
    // );

    return this.modelProvider.workspaceMember.findAllByUser$(userRef)
      .pipe(
        switchMap(members =>
          combineLatest(
            members.map(member => member.workspace$)
          )
        )
      )


    // return this.findAllBy(ref => ref.where('members.' + userRef.id + '.active', '==', true));
  }

  findAllByOrganization(organization: Organization) {
    return this.findAllBy(ref => ref.where('ownerOrganizationRef', '==', organization.ref));
  }

  protected instantiate(path, data, options?: any) {
    return new Workspace(path, data, options, this.modelProvider);
  }

  uploadFile(category: string, file: File, metadata?: any, extras?: any) {
    const reference = this.modelProvider.fsStorage.ref(this.getStorageReferencePath(category, file, extras));
    return {
      ref: reference,
      uploadTask: reference.put(file, { customMetadata: metadata || {} })
    };
  }

  getStorageReferencePath(category: string, file: File, extras?: any) {
    let fileName = file.name;
    if (extras && extras.fileName) {
      const re = /(?:\.([^.]+))?$/;
      const extension = re.exec(file.name)[1];   // "txt"
      fileName = extras.fileName + '-' + Date.now() + "." + extension;
    }
    return this.getDocument().ref.path + '/' + category + '/' + fileName;
  }

  hasReference(workspace: Workspace) {
    return workspace && this.getReference().id === workspace.getReference().id;
  }

  public async isValidScriptLimit(toCreate = true) {
    const organization = await this.ownerOrganization$.pipe(first()).toPromise();
    const plan = await organization.plan$.pipe(first()).toPromise();

    if (!['active', 'trialing'].includes(organization.plan.status)) { return false; }

    if (plan.features.scriptPerWorkspaceLimit < 0) { return true; }

    const scriptsAll = await this.scripts$.pipe(first()).pipe(first()).toPromise();
    const scripts = scriptsAll.filter(script => !!!script.isTemplate);
    return (scripts.length + (toCreate ? 1 : 0)) <= plan.features.scriptPerWorkspaceLimit;
  }

  public async isValidDesignerLimit(toCreate = true) {
    const organization = await this.ownerOrganization$.pipe(first()).toPromise();
    const plan = await organization.plan$.pipe(first()).toPromise();

    if (!['active', 'trialing'].includes(organization.plan.status)) { return false; }

    if (plan.features.designerLimitPerWorkspace < 0) { return true; }

    const members = await this.members$.
      pipe(
        map(members => members.filter(member => ['owner', 'admin', 'editor'].includes(member.level) && member.active))
        , first()
      )
      .toPromise();

    return (members.length + (toCreate ? 1 : 0)) <= plan.features.designerLimitPerWorkspace;

    // const members = Object.values(this.members.items).filter(member => ['owner', 'admin', 'editor'].includes(member.level) && member.active);
    // return (members.length + (toCreate ? 1 : 0)) <= plan.features.designerLimitPerWorkspace;
  }

  getRefByPath() {
    
  }

}
