import { FirestoreModel } from '../firebase/firestore-model';
import { Observable, ReplaySubject, of } from 'rxjs';
import { Workspace } from './workspace.model';
import { DocumentReference, QueryFn, QueryGroupFn } from '@angular/fire/firestore';
import firebase from "firebase/app"
import { ModelProvider } from './general/model.provider';
import { User } from './user.model';
import { Organization } from './organization.model';
import { filter, switchMap } from 'rxjs/operators';

export type OrganizationMemberLevel = 'owner' | 'admin' | 'billing' | 'player';

export class OrganizationMember extends FirestoreModel<OrganizationMember> {
  public COLLECTION_NAME = 'members';

  active: boolean;
  level: OrganizationMemberLevel;
  userRef: DocumentReference;
  joinedAt: firebase.firestore.Timestamp;

  protected rules() {
    return {
      active: {},
      level: {},
      userRef: {},
      joinedAt: {}
    };
  }

  // tslint:disable-next-line: member-ordering
  protected instantiate(path, data, options?: any) {
    return new OrganizationMember(path, data, options, this.modelProvider);
  }

  get organizationRef() {
    return this.ref.parent.parent;
  }

  /**
   * User
   */
  private _user$: Observable<User>;
  public get user$(): Observable<User> {
    if (this._user$ !== undefined) { return this._user$; }

    this._user$ = this.modelProvider.user.findByRef(this.userRef);
    return this._user$;
  }

  public findAllByOrganization(organization: Organization, query?: QueryFn) {
    if (!query) { query = ref => ref; }

    return this.findAllBy(ref => query(ref), organization.ref.collection('members'));
  }

  public findAllByUser$(user: User, queryFn?: QueryGroupFn): Observable<Array<OrganizationMember>> {
    if (!queryFn) { queryFn = ref => ref; }

    const queryRaw = queryFn(
      firebase.firestore().collectionGroup('members').where('userRef', '==', user.ref)
    );

    return this.findAllBy(ref => queryRaw);
  }

  public findAllByUserRef$(userRef: DocumentReference, queryFn?: QueryGroupFn): Observable<Array<OrganizationMember>> {
    if (!queryFn) { queryFn = ref => ref; }

    const queryRaw = queryFn(
      firebase.firestore().collectionGroup('members').where('userRef', '==', userRef)
    );

    return this.findAllBy(ref => queryRaw);
  }

  private _findAllMy$: Observable<Array<OrganizationMember>>
  public get findAllMy$() {
    if(this._findAllMy$){ return this._findAllMy$; }

    // this.modelProvider.user.getMe$()
    // this.modelProvider.fsAuth.onAuthStateChanged(a => {
    //   a.
    // })
    // this.modelProvider.user.organizationMembers$
    // this.modelProvider.fsAuth.
    this._findAllMy$ = this.modelProvider.user.getMe$().pipe(filter(user => !!user), switchMap(user => {
      console.log('AAAA', user)
      if(user) {
        return this.modelProvider.organizationMember.findAllByUser$(user);
      } else {
        return of([]);
      }
    }))
    console.log('AAA!');
    return this._findAllMy$;
  }

  // public findAllByUser(user: User, query?: QueryFn) {
  //   if (!query) { query = ref => ref; }

  //   return this.findAllBy(ref => query(ref), organization.ref.collection('members'));
  // }

  async changeLevel(level: OrganizationMemberLevel) {
    const data = await this.modelProvider.functions.httpsCallable('organizationChangeLevel')({
      memberPath: this.ref.path,
      level: level,
    }).toPromise();

    return data;
  }

  public async deactivate(isDeleteFromWorkspaces: boolean) {
    const data = await this.modelProvider.functions.httpsCallable('organizationMemberDeactivate')({
      memberPath: this.ref.path,
      isDeleteFromWorkspaces: isDeleteFromWorkspaces
    }).toPromise();

    return data;
  }

  public async reactivate() {
    const data = await this.modelProvider.functions.httpsCallable('organizationMemberReactivate')({
      memberPath: this.ref.path,
    }).toPromise();

    return data;
  }

}
