import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PrivilegeService } from './privilege.service';
import { environment } from 'src/environments/environment';
import { WorkspaceService } from '../workspace/workspace.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrivilegeGuard implements CanActivate {
  constructor(
    private privilegeService: PrivilegeService,
    private router: Router,
    private workspaceService: WorkspaceService
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!route.data.privilege) {
      throw new Error(`The property "privilege" is missing from the router data when the user try to navigate to URL:"${state.url}"`);
    }


    if (environment.app === 'designer') {
      const hasWorkspace = await this.workspaceService.hasDesignerWorkspace$().pipe(first()).toPromise();
      console.log('has workspace', hasWorkspace);
      if (!hasWorkspace) {
        await this.router.navigateByUrl('/organization/create/free');
        return;
      }
    }

    console.log('has access', 0);
    const hasAccess = await this.privilegeService.hasAccess(route.data.privilege);
    console.log('has access', 1);
    if (!hasAccess) {
      const redirectURL = (route.data.redirectTo) ? route.data.redirectTo : environment.homeURL;
      this.router.navigateByUrl(redirectURL);
      return false;
    } else {
      return true;
    }
  }
}
