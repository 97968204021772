import { FirestoreModel } from '../firebase/firestore-model';
import { Observable, of, ReplaySubject } from 'rxjs';
import { Script } from './script.model';
import { DocumentReference, QueryFn } from '@angular/fire/firestore';
import { Model } from './general/model';
import { WorkspaceMember } from './workspace-member';
import { FirestoreMapField } from '../firebase/firestore-map-field';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { Workspace } from './workspace.model';
import { OrganizationMember, OrganizationMemberLevel } from './organization-members.model';
import { Plan } from './plan';
import firebase from "firebase/app"
import { ImageWithSizes } from './general/image-with-sizes';

export type OrganizationLevel = 'owner' | 'admin' | 'billing';

export type PlanStatus = 'trialing' | 'active' | 'incomplete' | 'incomplete_expired' | 'past_due' | 'canceled' | 'unpaid';

export class Organization extends FirestoreModel<Organization> {
  public COLLECTION_NAME = 'organizations';

  public title: string;
  
  _image: ImageWithSizes;
  public get imageUrl() {
    return this._image && this._image.getSizedUrl('576x') ? this._image.getSizedUrl('576x') : this.photoURL;
  }
  public set imageUrl(url: string) {
    this._image.setSizedUrl(url);
  }
  // public photoURL: string;
  public plan: {
    ref: DocumentReference,
    status: PlanStatus;
    currentPeriodStart: firebase.firestore.Timestamp;
    currentPeriodEnd: firebase.firestore.Timestamp;
    cancelAt: firebase.firestore.Timestamp;
    collectionMethod: 'charge_automatically' | 'send_invoice';
  };
  public stripe: {
    customerID: string,
    priceID: string,
    subscriptionID: string
  };
  public integrations: {
    providerID: string,
    sso: boolean,
    lti: boolean
  }
  public color: {
    primary: string,
    warning: string,
  }

  protected rules() {
    return {
      title: {},
      photoURL: {},
      _image: { 
        [Model.RULE_ALIAS]: 'image',
        [Model.RULE_CLASS]: ImageWithSizes,
        [Model.RULE_DEFAULT]: () => new ImageWithSizes({}, this, {}, this.modelProvider)
      },
      plan: {
        [Model.RULE_DEFAULT]: {
          status: 'active'
        }
      },
      stripe: {
        [Model.RULE_DEFAULT]: {}
      },
      integrations: {},
      color: {}
    };
  }

  public get isActive() {
    return ['active', 'trialing'].includes(this.plan.status);
  }

  /**
   * Workspaces
   */
  // tslint:disable-next-line: member-ordering
  private _workspaces$: ReplaySubject<Workspace[]>;
  public get workspaces$(): Observable<Workspace[]> {
    if (this._workspaces$ !== undefined) { return this._workspaces$; }

    this._workspaces$ = new ReplaySubject<Workspace[]>(1);
    // return this.findAllBy(ref => ref.where('teamRef', '==', team.getDocument().ref));
    this.modelProvider.workspace.findAllByOrganization(this).subscribe(this._workspaces$);
    return this._workspaces$;
  }

  // tslint:disable-next-line: member-ordering
  private _workspaces: Array<Workspace>;
  public get workspaces(): Array<Workspace> {
    if (this._workspaces !== undefined) { return this._workspaces; }
    this._workspaces = [];

    this.workspaces$.subscribe(workspaces => {
      this._workspaces = workspaces;
    });
    return this._workspaces;
  }

  /**
   * Package
   */
  // tslint:disable-next-line: member-ordering
  private _plan$: Observable<Plan>;
  public get plan$(): Observable<Plan> {
    if (this._plan$ !== undefined) { return this._plan$; }

    this._plan$ = this.modelProvider.plan.findByRef(this.plan.ref);
    return this._plan$;

    // this._plan$ = new ReplaySubject<Plan>(1);
    // this.modelProvider.plan.findByRef(this.plan.ref).subscribe(plans => {
    //   console.log(plans);
    //   if (this.plan && this.plan.name) {
    //     this._plan$.next(plans[this.plan.name] as Plan);
    //   } else {
    //     this._plan$.next(null);
    //   }
    // });
    // return this._plan$;
  }

  /**
   * Members
   */
  // tslint:disable-next-line: member-ordering
  private _members$: ReplaySubject<OrganizationMember[]>;
  public get members$(): Observable<OrganizationMember[]> {
    if (this._members$ !== undefined) { return this._members$; }

    this._members$ = new ReplaySubject<OrganizationMember[]>(1);
    this.modelProvider.organizationMember.findAllByOrganization(this).subscribe(this._members$);
    return this._members$;
  }

  // public getMemberByUser(userRef: DocumentReference) {
  //   return this.members.getByID(userRef.id);
  // }

  //   public getLevelByUser(userRef: DocumentReference) {
  //     return this.getMemberByUser(userRef).level;
  //   }

  private _myMember$: Observable<OrganizationMember>;
  public get myMember$() {
    if (this._myMember$) { return this._myMember$; }

    if(this.isOrphan()) {
      this._myMember$ = of(null);
      return this._myMember$;
    }

    const ref = this.ref.collection('members').doc(this.modelProvider.user.meReference.id);
    this._myMember$ = this.modelProvider.organizationMember.findByRef(ref).pipe(catchError(_ => of(null)));

    return this._myMember$;
  }

  public async createFreeOrganization(title: string, category: string, size: string) {
    return await this.modelProvider.functions.httpsCallable('organizationCreateFree')({
      title: title,
      category: category,
      size: size,
    }).pipe(first()).toPromise();
  }

  public async inviteMember(email: string, level: OrganizationMemberLevel, isSendEmail = true) {
    return await this.modelProvider.functions.httpsCallable('organizationInviteMember')({
      email: email,
      level: level,
      organizationPath: this.ref.path,
      sendEmail: isSendEmail
    }).pipe(first()).toPromise();
  }

  public async cancelSubscription() {
    return await this.modelProvider.functions.httpsCallable('organizationCancelSubscription')({
      organizationPath: this.ref.path
    }).pipe(first()).toPromise();
  }

  public async reactivateSubscription() {
    return await this.modelProvider.functions.httpsCallable('organizationReactivateSubscription')({
      organizationPath: this.ref.path
    }).pipe(first()).toPromise();
  }

  //   public join(workspaceID?: string) {
  //     if (!workspaceID) {
  //       workspaceID = this.ref.id;
  //     }
  //     return this.modelProvider.functions.httpsCallable('workspaceJoin')({
  //       workspaceID: workspaceID
  //     }).pipe(first()).toPromise();
  //   }

  //   public findAllByUser(userRef: DocumentReference) {
  //     return this.findAllBy(ref => ref.where('members.' + userRef.id + '.active', '==', true));
  //   }

  protected instantiate(path, data, options?: any) {
    return new Organization(path, data, options, this.modelProvider);
  }

  uploadFile(category: string, file: File, metadata?: any, extras?: any) {
    const reference = this.modelProvider.fsStorage.ref(this.getStorageReferencePath(category, file, extras));
    return {
      ref: reference,
      uploadTask: reference.put(file, { customMetadata: metadata || {} })
    };
  }

  getStorageReferencePath(category: string, file: File, extras?: any) {
    let fileName = file.name;
    if (extras && extras.fileName) {
      const re = /(?:\.([^.]+))?$/;
      const extension = re.exec(file.name)[1];   // "txt"
      fileName = extras.fileName + '-' + Date.now() + "." + extension;
    }
    return this.getDocument().ref.path + '/' + category + '/' + fileName;
  }

  hasReference(workspace: Workspace) {
    return workspace && this.getReference().id === workspace.getReference().id;
  }

  private _photoURL: string;
  get photoURL(): string {
    return this._photoURL ?
      this._photoURL :
      '/assets/images/img-placeholder.png';
  }

  set photoURL(value: string) {
    this._photoURL = value;
  }

  async isValidWorkspaceLimit(toCreate = true) {

    if (!['active', 'trialing'].includes(this.plan.status)) { return false; }

    const user = await this.modelProvider.user.getMe();

    const workspaces = await user.workspaces$
      .pipe(mergeMap(async workspaces => {
        return await this.modelProvider.asyncFilter(workspaces, async workspace => 
          (await workspace.getMyMember()).level !== 'player'
        );
        // workspaces.filter(workspace => workspace.getMyMember().level !== 'player')
      }))
      .pipe(map(workspaces => {
        return workspaces.filter(workspace => {
          if (workspace.ownerOrganizationRef) {
            return workspace.ownerOrganizationRef.isEqual(this.ref);
          } else {
            return this.isOrphan();
          }
        });
      }))
      .pipe(first())
      .toPromise();

    const plan = await this.plan$.pipe(first()).toPromise();
    if (plan.features.workspaceLimit < 0) { return true; }

    console.log(workspaces.length, plan.features.workspaceLimit);

    return (workspaces.length + (toCreate ? 1 : 0)) <= plan.features.workspaceLimit;
  }

  public orphanRef = this.modelProvider.fsDB.collection('organizations').doc('orphan').ref;
  isOrphan() {
    return this.ref.isEqual(this.orphanRef);
  }

  getOrphanOrganization() {
    const model = this.modelProvider.organization.create(this.modelProvider.organization.orphanRef.path, {
      title: 'Personal Workspace',
      plan: {
        ref: this.modelProvider.fsDB.collection('plans').doc('free').ref,
        status: 'active'
      }
    })

    return model;
  }

}
