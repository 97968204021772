import { FirestoreModel } from '../firebase/firestore-model';
import { take, switchMapTo, switchMap, first, mergeMap, tap, concatMap, concatAll, shareReplay, map, filter } from 'rxjs/operators';
import { Observable, ReplaySubject, combineLatest, forkJoin, concat, of } from 'rxjs';
import { Workspace, WorkspaceLevel } from './workspace.model';
import firebase from "firebase/app"
import { DocumentReference } from '@angular/fire/firestore';
import { OrganizationMember } from './organization-members.model';
import { Organization } from './organization.model';
import { Model } from './general/model';

export class User extends FirestoreModel<User> {
  public COLLECTION_NAME = 'users';

  // public createdAt: Date;
  public device: any;
  private _displayName: string;
  public get displayName(): string {
    if (this._displayName && this._displayName.length > 0) {
      return this._displayName;
    }

    return this.email && this.email.replace(/(\w{2})[\w.-]+@([\w.]+\w)/, "$1***@$2")
  }
  public set displayName(text: string) {
    this._displayName = text;
  }
  public email: string;
  public newsletter: boolean;
  public lastLoginAt: firebase.firestore.Timestamp;
  public organizations: Object = {};
  public phoneNumber: string;
  public language: string | 'en' | 'es';
  public location: string;
  public aboutMe: string;
  public role: string;
  public roles: {
    designer: {
      active: boolean;
      lastLoginAt: firebase.firestore.Timestamp,
      registeredAt: firebase.firestore.Timestamp,
    },
    player: {
      active: boolean;
      lastLoginAt: firebase.firestore.Timestamp,
      registeredAt: firebase.firestore.Timestamp,
    },
  }
  public deleted: {
    is: boolean,
    at: firebase.firestore.Timestamp,
    by: DocumentReference
  }
  public version: string; // Latest app version installed,
  public kaya: {
    id: string;
  }
  public providers: {
    [providerID: string]: {
      active: boolean,
      organizationRef: DocumentReference,
      userID: string
    }
  }
  public workspaces: {
    [workspaceID: string]: {
      active: boolean,
      level: WorkspaceLevel,
      workspaceRef: DocumentReference,
      joinedAt: firebase.firestore.Timestamp
    }
  };

  private _photoURL: string;

  protected rules() {
    return {
      createdAt: {},
      device: {},
      _displayName: {
        [Model.RULE_ALIAS]: 'displayName',
      },
      email: {},
      lastLoginAt: {},
      organizations: {},
      phoneNumber: {},
      photoURL: {},
      language: {},
      location: {},
      aboutMe: {},
      role: {},
      roles: {},
      version: {},
      kaya: {},
      providers: {},
      workspaces: {},
      newsletter: {},
      deleted: {}
    };
  }

  get photoURL(): string {
    return this._photoURL ?
      this._photoURL :
      'https://firebasestorage.googleapis.com/v0/b/gamoteca-161414.appspot.com/o/users%2Ffacilitator%2Favatar.png?alt=media&token=5265ea4d-525a-4212-873c-96aa13c9b649';
  }

  set photoURL(value: string) {
    this._photoURL = value;
  }

  isProfileDataMissing() {
    return !this.location || !this.aboutMe || !this.language;
  }

  protected instantiate(path, data, options?: any) {
    return new User(path, data, options, this.modelProvider);
  }

  public async getMe(): Promise<User> {
    if (!firebase.auth().currentUser) {
      return null;
    }
    return await this.findByID(firebase.auth().currentUser.uid).pipe(take(1)).toPromise();
  }

  public getMe$(): Observable<User> {
    console.log('this.modelProvider.fsAuth', this.modelProvider.fsAuth);
    return this.modelProvider.fsAuth.user.pipe(
      filter(user => !!user),
      switchMap(user => this.findByID(user.uid)),
      shareReplay(1)
    );

    // return this.findByID(
    //   firebase.auth().currentUser.uid
    // ).pipe(tap(a => console.log('www', a)), shareReplay(1));

    // return this.findByID(
    //   firebase.auth().currentUser.uid
    // ).pipe(tap(a => console.log('www', a)), shareReplay(1));
  }

  public get languageText() {
    if(!this.language) { return null }
    const languages = [
      {
        code: 'en',
        language: 'english'
      },
      {
        code: 'es',
        language: 'spanish'
      }
    ];
    return languages.find(languageItem => languageItem.code === this.language)?.language
  }

  public get facilitatorRef() {
    return this.modelProvider.fsDB.collection('users').doc('facilitator').ref
  }

  public get meReference() {
    if (!firebase.auth().currentUser) {
      return null;
    }
    // this.modelProvider.fsAuth.
    return this.modelProvider.fsDB.collection('users').doc(firebase.auth().currentUser.uid).ref;
  }

  public get isMe() {
    return this.ref.id === firebase.auth().currentUser?.uid;
  }

  public get isFacilitator() {
    return this.ref.path === this.facilitatorRef.path;
  }

  /**
   * Workspace
   */
  // tslint:disable-next-line: member-ordering
  private _workspaces$: Observable<Workspace[]>;
  public get workspaces$(): Observable<Workspace[]> {
    if (this._workspaces$ !== undefined) { return this._workspaces$; }
    this._workspaces$ = this.modelProvider.workspace.findAllByUser(this.getReference())
    return this._workspaces$;
  }

  /**
   * Organization Members
   */
  // tslint:disable-next-line: member-ordering
  private _organizations$: ReplaySubject<Organization[]>;
  public get organizations$(): Observable<Organization[]> {
    if (this._organizations$ !== undefined) { return this._organizations$; }

    this._organizations$ = new ReplaySubject<Organization[]>(1);
    console.log(this);
    this.modelProvider.organizationMember
      .findAllByUser$(this)
      .pipe(
        switchMap(organizationMembers => {
          console.log(organizationMembers)
          return combineLatest(
            organizationMembers.map(member =>
              this.modelProvider.organization
                .findByRef(member.organizationRef)
            )
          );
        })
      )
      // .pipe(tap(orgs => console.log(orgs)))
      .subscribe(this._organizations$);
    return this._organizations$;
  }

  // public async getOrganizationsPartOf$(): Observable<Organization[]> {
  //   await this.modelProvider.asyncFilter(workspaces, async (workspace) =>
  //   (await workspace.myMember()).level !== 'player'
  //   );
  // }

  /**
   * Organization Members
   */
  // tslint:disable-next-line: member-ordering
  private _organizationsPartOf$: Observable<Organization[]>;
  public get organizationsPartOf$(): Observable<Organization[]> {
    if (this._organizationsPartOf$ !== undefined) { return this._organizationsPartOf$; }

    const organizationsByWorkspaceMember$ = this.workspaces$.pipe(
      switchMap(async workspaces => {
        await this.modelProvider.asyncFilter(workspaces, async (workspace: Workspace) => {
          // console.log(workspace);
          // const member = await workspace.getMyMember();
          // if(!member?.level) {
          //   console.log(member, workspace)
          //   // console.log((await workspace.getMyMember()));
          // }
          // return false;
          return (await workspace.getMyMember()).level !== 'player'
        }

        );

        return workspaces;
      }),
      switchMap(workspaces => {
        // console.log('organizationsByWorkspaceMember$', workspaces.filter(workspace => workspace.ownerOrganizationRef).map(w => w.ownerOrganizationRef && w.ownerOrganizationRef.path));
        if (workspaces.filter(workspace => workspace.ownerOrganizationRef).length === 0) {
          return of([]);
        }

        return combineLatest(
          workspaces
            .filter(workspace => workspace.myMember.level !== 'player')
            .filter(workspace => workspace.ownerOrganizationRef)
            .map(workspace => this.modelProvider.organization.findByRef(workspace.ownerOrganizationRef))
        );
      })
    )
    // .pipe(tap(orgs => console.log('organizationsByWorkspaceMember$', orgs)))

    const organizationsByMember$ = this.modelProvider.organizationMember
      .findAllByUser$(this)
      .pipe(
        switchMap(organizationMembers => {
          // console.log('organizationsByMember$', organizationMembers)

          if (organizationMembers.length === 0) {
            return of([]);
          }

          // console.log('organizationsByMember$', organizationsByMember$);
          return combineLatest(
            organizationMembers
            // .filter(member => member.level !== 'player')
            .map(member =>
              this.modelProvider.organization
                .findByRef(member.organizationRef)
            )
          );
        })
      )

    this._organizationsPartOf$ = combineLatest([organizationsByWorkspaceMember$, organizationsByMember$])
      // .pipe(tap(orgs => console.log('orgs',orgs)))
      .pipe(map(([organizationsByWorkspaceMember, organizationsByMember]) => organizationsByWorkspaceMember.concat(organizationsByMember)))
      // Remove duplication: https://ilikekillnerds.com/2016/05/removing-duplicate-objects-array-property-name-javascript/
      .pipe(map(organizations => organizations
        .filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj.ref.path).indexOf(obj.ref.path) === pos;
        })
      ))
      .pipe(shareReplay(1))

    return this._organizationsPartOf$;
  }

  /**
   * Organizations
   */
  // tslint:disable-next-line: member-ordering
  private _organizationMembers$: Observable<OrganizationMember[]>;
  public get organizationMembers$(): Observable<OrganizationMember[]> {
    if (this._organizationMembers$) { return this._organizationMembers$; }

    console.log('kkk', this)
    this._organizationMembers$ = this.modelProvider.user.getMe$().pipe(switchMap(user => this.modelProvider.organizationMember.findAllByUser$(user)));
    // this._organizationMembers$ = this.modelProvider.organizationMember.findAllByUser$(this);
    return this._organizationMembers$;

    // this._organizationMembers$ = new ReplaySubject<OrganizationMember[]>(1);
    // this.modelProvider.organizationMember.findAllByUser$(this).subscribe(this._organizationMembers$);
    // return this._organizationMembers$;
  }

  // // tslint:disable-next-line: member-ordering
  // private _workspaces: Array<Workspace>;
  // public get workspaces(): Array<Workspace> {
  //   if (this._workspaces !== undefined) { return this._workspaces; }
  //   this._workspaces = [];

  //   this.workspaces$.subscribe(workspaces => {
  //     this._workspaces = workspaces;
  //   });
  //   return this._workspaces;
  // }

  uploadFile(category: string, file: File, metadata?: any) {
    const reference = this.modelProvider.fsStorage.ref(this.getStorageReferencePath(category, file));
    return {
      ref: reference,
      uploadTask: reference.put(file, metadata)
    };
  }

  getStorageReferencePath(category: string, file: File) {
    return this.getDocument().ref.path + '/' + category + '/' + file.name;
  }

  joinToWorkspace(workspace: Workspace, timestamp: firebase.firestore.Timestamp) {
    if (this.workspaces[workspace.id] && !this.isJoinedToTheWorkspace(workspace)) {
      this.workspaces[workspace.id].joinedAt = timestamp;
      this.save();
    }
  }

  isJoinedToTheWorkspace(workspace: Workspace) {
    return this.workspaces[workspace.id] && this.workspaces[workspace.id].joinedAt;
  }

  getProviderByOrganizationRef(organizationRef: DocumentReference) {
    if (!organizationRef) { return false; }

    return Object.values(this.providers).find(provider => provider.organizationRef.path === organizationRef.path);
  }

  async deleteAccount() {
    return await this.modelProvider.functions.httpsCallable('deleteUserAccount')({}).pipe(first()).toPromise();
  }
}
