import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs';

import firebase from "firebase/app"
import { AuthService } from './auth.service';
import { Location } from '@angular/common';
import { first, map, shareReplay, skip, tap } from 'rxjs/operators'
import { NavController } from '@ionic/angular';
import { ModelProvider } from '../core/models/general/model.provider';
import { environment } from 'src/environments/environment';
// import { OrganizationService } from '../organization/organization.service';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private locationProvider: Location,
    private navCtrl: NavController,
    private modelProvider: ModelProvider,
    // private organizationService: OrganizationService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {

    return new Promise(async (resolve, reject) => {
      this.authService.authState().pipe(first()).subscribe(async user => {
        if (state.root.queryParamMap.has('verificationCode')) {
          try {
            console.log('verification code:', state.root.queryParamMap.get('verificationCode'));
            await firebase.auth().applyActionCode(state.root.queryParamMap.get('verificationCode'));
          } catch (error) {
            console.error(error);
          }
        }

        const whiteListedRoutes = ['playing/browse/design-details/:scriptID', 'organization/create/free'];

        if (state.root.queryParamMap.has('apiKey') && state.root.queryParamMap.get('mode') === 'signIn' && state.root.queryParamMap.has('email')) {
          try {
            await this.authService.singInWithEmailLink(
              state.root.queryParamMap.get('email'),
              window.location.href
            );

            if(whiteListedRoutes.includes(next.routeConfig.path)) {
              resolve(true);
              return;
            }

            // Handle has organization
            if(environment.app === 'player') {
              const hasOrganization = await this.hasOrganization();
              console.log('hasOrganization', hasOrganization);
              if(!hasOrganization) {
                this.router.navigate(['/auth/org-not-found'], { queryParams: { returnUrl: state.url } });
                resolve(false);
              }
            }
            
            resolve(true);
            return;
          } catch (error) {
            console.error(error);
          }
        }

        if(!user) {
          await this.authService.setHomeURL(state.url);
        }

        if (user) {
          console.debug('user logged in: ', user.uid);
          if (!user.emailVerified && user.providerData.find((provider) => provider.providerId === 'password')) {
            await this.router.navigate(['/auth/verification-required'], { queryParams: { returnUrl: state.url } });
            resolve(false);
            return;
          } else {
            // Handle has organization
            if(environment.app === 'player') {
              const hasOrganization = await this.hasOrganization();
              console.log('hasOrganization', hasOrganization);
              if(hasOrganization) {
                
              } else {
                if(whiteListedRoutes.includes(next.routeConfig.path)) {
                  resolve(true);
                  return;
                }
                
                this.router.navigate(['/auth/org-not-found'], { queryParams: { returnUrl: state.url } });
                resolve(false);
              }
            }

            resolve(true);
          }
        } else {
          // User is not authenticated
          console.debug('User is not authenticated', user, 'redirect to:', '/welcome');

          // console.log(state.url);

          // this.authService.setHomeURL(state.url);
          await this.navCtrl.navigateRoot(['/welcome'], {
            queryParams: {
              partner: state.root.queryParamMap.get('partner'),
            },
            // queryParamsHandling: 'merge'
            // queryParamsHandling: 'merge',
          });
          // this.router.navigate(['/welcome'], { queryParams: { partner: state.root.queryParamMap.get('partner') } });
          resolve(false);
        }
      });
    });
  }

  async hasOrganization() {
    const user = await this.modelProvider.user.getMe();
    return await user.organizationMembers$
    .pipe(
      tap(members => console.log('MEMBERS',members)),
      map(members => {
        return members?.length ? true : false;
      }),
      first()
    ).toPromise();
  }
}
