<div *ngIf="activeTab !== 'players'" class="actions text-right mb-3">
  <ion-button shape="round" (click)="openInviteMember()">
    <mat-icon slot="start">add</mat-icon>
    {{'organization/invite-member/invite-button/label' | translate}}
  </ion-button>
</div>
<div class="clearfix"></div>
<div class="row member-row mt-5" *ngFor="let member of members; let i = index">
  <ng-container *ngIf="(member.user$ | async); let user;">
    <div class="col-md-2 col-lg-1 vcenter member-picture" [ngStyle]="{'opacity': member.active ? 1 : 0.4}">
      <img [src]="user.photoURL" alt="Member profile picture">
    </div>
    <div class="col-md-4 col-lg-5 vcenter name" [ngStyle]="{'opacity': member.active ? 1 : 0.4}">
      {{ user.displayName }}
      <ng-container *ngIf="!user.displayName">{{user.email}}</ng-container>
      <span *ngIf="!member.active">
        &nbsp;({{ 'organization-members/remove-from-team/removed' | translate }})
      </span>
    </div>
    <div class="col-md-5 vcenter role" [ngStyle]="{'opacity': member.active ? 1 : 0.4}">
      <div class="progress-loader-wrapper">
        <ion-spinner *ngIf="processingMembers.includes(member)" name="lines-small"></ion-spinner>
      </div>

      <form *ngIf="true || activeTab !== 'players'">
        <select [ngModel]="member.level" [name]="member"
          [disabled]="processingMembers.includes(member) || !member.active"
          (change)="changeLevel(member, $event.target.value)" class="form-control">
          <option [value]="'owner'">{{'organization/members/role/owner' | translate }}</option>
          <option [value]="'admin'">{{'organization/members/role/admin' | translate }}</option>
          <option [value]="'billing'">{{'organization/members/role/billing' | translate }}</option>
          <option [value]="'player'">{{'organization/members/role/player' | translate }}</option>
        </select>
      </form>

      <!-- <div *ngIf="!(workspaceEditPrivilege | privilege) | async">
        {{ member.level }}
      </div> -->
    </div>

    <div class="col-md-1 vcenter member-actions">
      <mat-icon *ngIf="member.active" [title]="'organization/members/remove/menu-item' | translate"
      popperAppendTo="body" (click)="showRemoveFromTeamConfirmationDialog(member)">delete</mat-icon>

      <!-- <mat-icon *ngIf="member.active" [title]="'manage-teams/remove-from-team/menu-item' | translate"
        popperAppendTo="body" (click)="showRemoveFromTeamConfirmationDialog(member.userRef.id)">checkbox</mat-icon> -->

      <mat-icon *ngIf="!member.active" [title]="'manage-teams/add-to-team-again/menu-item' | translate"
        (click)="showAddUserToTeamConfirmationDialog(member)">check_box_outline_blank</mat-icon>
    </div>
  </ng-container>
</div>