import { FirestoreModel } from '../firebase/firestore-model';
import { ScriptVersion } from './script-version.model';
import { FirebaseFileUploaderEntity } from '../firebase/firebase-file-uploader-entity';
import firebase from "firebase/app"
import { ReplaySubject, Observable, combineLatest } from 'rxjs';
import { Task } from './task.model';
import { DocumentReference, QueryFn } from '@angular/fire/firestore';
import { Workspace } from './workspace.model';
import { first, map, shareReplay, tap } from 'rxjs/operators';
import { Model } from './general/model';
import { merge } from 'lodash';
import { User } from './user.model';
import { VideoLayoutItem } from './layout-item-video';
import { Role } from './role.model';

export type ScriptAnalyticsType = {
    role: {
        randomSession: {
            [roleID: string]: {
                available: number
            }
        }
    },
    play: {
        total: {
            initialized: number;
            started: number;
            finished: number;
        };
        customSession: {
            initialized: number;
            started: number;
            finished: number;
        };
        inviteSession: {
            initialized: number;
            started: number;
            finished: number;
        };
        randomSession: {
            initialized: number;
            started: number;
            finished: number;
        };
    };
    player: {
        total: {
            reached: number;
            finished: number;
            timeSpent: number;
        };
        customSession: {
            reached: number;
            finished: number;
            timeSpent: number;
        };
        inviteSession: {
            reached: number;
            finished: number;
            timeSpent: number;
        };
        randomSession: {
            reached: number;
            finished: number;
            timeSpent: number;
        };
    }
};

export class Script extends FirestoreModel<Script> implements FirebaseFileUploaderEntity {
    public COLLECTION_NAME = 'scripts';

    lastModified: firebase.firestore.Timestamp;
    status: string;
    active: boolean;
    workspaceRef: DocumentReference;
    public: {
        active: boolean;
        publishedAt: firebase.firestore.Timestamp;
        publishedBy: DocumentReference;
        revokedAt?: firebase.firestore.Timestamp;
        revokedBy?: DocumentReference;
        versionRef: DocumentReference;
        versionLastUpdatedAt: firebase.firestore.Timestamp;
        autoJoinEnabled: boolean;
        feed: {
            postEnabled: boolean;
        }
    };
    legacy: any;
    // isDemo: boolean;
    demo: {
        is: boolean,
        copyFromScriptRef: DocumentReference,
        copyFromScriptVersionRef: DocumentReference
    }
    moodle: {
        uri?: string;
        function?: string;
        token?: string;
        courseID?: number;
        moduleID?: number;
    };

    private _analytics: ScriptAnalyticsType;
    public get analytics(): ScriptAnalyticsType {
        return this._analytics;
    }
    public set analytics(value: ScriptAnalyticsType) {
        const defaultValue: ScriptAnalyticsType = {
            role: {
                randomSession: {

                }
            },
            play: {
                total: {
                    initialized: 0,
                    started: 0,
                    finished: 0,
                },
                customSession: {
                    initialized: 0,
                    started: 0,
                    finished: 0,
                },
                inviteSession: {
                    initialized: 0,
                    started: 0,
                    finished: 0,
                },
                randomSession: {
                    initialized: 0,
                    started: 0,
                    finished: 0,
                }
            },
            player: {
                total: {
                    reached: 0,
                    finished: 0,
                    timeSpent: 0,
                },
                customSession: {
                    reached: 0,
                    finished: 0,
                    timeSpent: 0,
                },
                inviteSession: {
                    reached: 0,
                    finished: 0,
                    timeSpent: 0,
                },
                randomSession: {
                    reached: 0,
                    finished: 0,
                    timeSpent: 0,
                }
            }
        };

        this._analytics = merge(defaultValue, value);
    }

    protected rules() {
        return {
            lastModified: {},
            status: {},
            active: {},
            // isDemo: {},
            demo: {},
            workspaceRef: {},
            public: {},
            legacy: {},
            moodle: {},
            analytics: {
                [Model.RULE_DEFAULT]: {
                    play: {
                        total: {
                            initialized: 0,
                            started: 0,
                            finished: 0,
                        },
                        customSession: {
                            initialized: 0,
                            started: 0,
                            finished: 0,
                        },
                        inviteSession: {
                            initialized: 0,
                            started: 0,
                            finished: 0,
                        },
                        randomSession: {
                            initialized: 0,
                            started: 0,
                            finished: 0,
                        }
                    },
                    player: {
                        total: {
                            reached: 0,
                            finished: 0,
                            timeSpent: 0,
                        },
                        customSession: {
                            reached: 0,
                            finished: 0,
                            timeSpent: 0,
                        },
                        inviteSession: {
                            reached: 0,
                            finished: 0,
                            timeSpent: 0,
                        },
                        randomSession: {
                            reached: 0,
                            finished: 0,
                            timeSpent: 0,
                        }
                    }
                }
            }
        };
    }

    /**
    * Versions
    */
    // tslint:disable-next-line: member-ordering
    private _versions$: ReplaySubject<ScriptVersion[]>;
    public get versions$(): Observable<ScriptVersion[]> {
        if (this._versions$ !== undefined) { return this._versions$; }

        this._versions$ = new ReplaySubject<ScriptVersion[]>(1);
        this.modelProvider.scriptVersion.findAllByScript(this).subscribe(this._versions$);
        return this._versions$;
    }

    // tslint:disable-next-line: member-ordering
    private _versions: Array<ScriptVersion>;
    public get versions(): Array<ScriptVersion> {
        if (this._versions !== undefined) { return this._versions; }
        this._versions = [];

        this.versions$.subscribe(versions => {
            this._versions = versions;
        });
        return this._versions;
    }

    // tslint:disable-next-line: member-ordering
    private _draft$: Observable<ScriptVersion>;
    public get draft$(): Observable<ScriptVersion> {
        if (this._draft$ !== undefined) { return this._draft$; }

        this._draft$ = this.modelProvider.scriptVersion.getDraftByScript(this)
        // .pipe(tap(draft => console.log('draft'))).pipe(shareReplay(1));
        // this._draft$ = new ReplaySubject<ScriptVersion>(1);
        // this.modelProvider.scriptVersion.getDraftByScript(this).subscribe(this._draft$);
        return this._draft$;
    }

    // tslint:disable-next-line: member-ordering
    private _draft: ScriptVersion;
    public get draft(): ScriptVersion {
        if (this._draft !== undefined) { return this._draft; }
        this._draft = null;

        this.draft$.subscribe(draft => {
            this._draft = draft;
        });
        return this._draft;
    }

    // tslint:disable-next-line: member-ordering
    private _publicVersion$: Observable<ScriptVersion>;
    private _publicVersion$Ref: DocumentReference;
    public get publicVersion$(): Observable<ScriptVersion> {
        if (this._publicVersion$ !== undefined && this._publicVersion$Ref.isEqual(this.public.versionRef)) { return this._publicVersion$; }

        this._publicVersion$Ref = this.public.versionRef;
        this._publicVersion$ = this.modelProvider.scriptVersion.getPublicByScript(this);
        return this._publicVersion$;
    }

    // tslint:disable-next-line: member-ordering
    private _publicVersion: ScriptVersion;
    public get publicVersion(): ScriptVersion {
        if (this._publicVersion !== undefined) { return this._publicVersion; }
        this._publicVersion = null;

        this.publicVersion$.subscribe(_public => {
            this._publicVersion = _public;
        });
        return this._publicVersion;
    }

    /**
     * Workspace
     */
    // tslint:disable-next-line: member-ordering
    private _workspace$: Observable<Workspace>;
    public get workspace$(): Observable<Workspace> {
        if (this._workspace$ !== undefined) { return this._workspace$; }

        this._workspace$ = this.modelProvider.workspace.findByRef(this.workspaceRef);
        return this._workspace$;
    }

    private _publisherUser$: Observable<User>;
    public get publisherUser$(): Observable<User> {
        if (this._publisherUser$ !== undefined) { return this._publisherUser$; }

        this._publisherUser$ = this.modelProvider.user.findByRef(this.public.publishedBy);
        return this._publisherUser$;
    }

    public async copyTo(workspaceRef?: DocumentReference) {
        const result = await this.modelProvider.functions.httpsCallable('copyScript')({
            'fromVersionPath': (await this.draft$.pipe(first()).toPromise()).ref.path,
            'toWorkspacePath': workspaceRef.path,
            extras: {
                'titlePrefix': 'Copy of '
            }
        }).toPromise();

        return result;
    }

    public async moveTo(workspaceRef?: DocumentReference) {
        this.workspaceRef = workspaceRef;
        await this.save();
    }

    public async publish() {
        // Check for processing video
        const draftVersion = await this.draft$.pipe(first()).toPromise();
        const tasks = await draftVersion.tasks$.pipe(first()).toPromise();
        for (const task of tasks) {
            const video = task.layout.getItemByType('video', false) as VideoLayoutItem;
            if (video && video.isTranscoding) {
                throw new Error('video-transcoding');
            }
        }

        const result = await this.modelProvider.functions.httpsCallable('publishScript')({
            'scriptPath': this.ref.path,
        }).toPromise();

        if (result && result.error) {
            throw new Error(result.error);
        }

        // console.log(result);

        return result;
    }

    public async publish2() {
        const transaction = this.modelProvider.fsDB.firestore.batch();

        // Create new public version
        const draftVersion = await this.draft$.pipe(first()).toPromise();
        const publicVersionID = this.modelProvider.fsDB.createId();
        const publicVersionRef = this.modelProvider.fsDB.doc(this.getReference().path + '/versions/' + publicVersionID).ref;
        transaction.set(
            publicVersionRef,
            draftVersion.toData()
        );

        const updateVersionReference = (docRef: DocumentReference) => {
            if (!docRef) {
                return docRef;
            }

            return this.modelProvider.fsDB.doc(
                docRef.path.replace(
                    /versions\/(\w+)\//gi,
                    'versions/' + publicVersionID + '/'
                )
            ).ref;
        };

        // Copy roles
        const roles = await draftVersion.roles$.pipe(first()).toPromise();
        for (const role of roles) {
            transaction.set(
                updateVersionReference(role.getReference()),
                role.toData()
            );
        }

        // Copy tasks
        const tasks = await draftVersion.tasks$.pipe(first()).toPromise();
        for (const task of tasks) {
            const taskData = task.toData();
            // Update dependency task reference
            taskData.roleRef = updateVersionReference(task.roleRef);
            taskData.dependency.rules = task.dependency.ruleSet ? task.dependency.ruleSet.map(rule => {
                rule.taskRef = updateVersionReference(rule.taskRef);
                return rule.toData();
            }) : [];

            // Update choice task reference
            for (const layoutID in task.layout.items) {
                if (task.layout.items.hasOwnProperty(layoutID)) {
                    const layoutItem = task.layout.items[layoutID];
                    if (layoutItem.properties && layoutItem.properties.taskRef) {
                        taskData.layout[layoutID].properties.taskRef = updateVersionReference(layoutItem.properties.taskRef);
                    }
                }
            }

            transaction.set(
                updateVersionReference(task.getReference()),
                taskData
            );
        }

        await transaction.commit();

        this.public = {
            ...this.public,
            active: true,
            publishedAt: firebase.firestore.Timestamp.now(),
            publishedBy: this.modelProvider.user.meReference,
            versionRef: publicVersionRef,
            versionLastUpdatedAt: draftVersion.updatedAt
        };
        await this.save();
    }

    public async unpublish() {
        this.public = {
            ...this.public,
            active: false,
            revokedAt: firebase.firestore.Timestamp.now(),
            revokedBy: this.modelProvider.user.meReference
        };
        await this.save();
    }


    public async isPublicDiffers() {
        return this.isPublicDiffers$.pipe(first()).toPromise();
    }

    // tslint:disable-next-line: member-ordering
    private _isPublicDiffers$: Observable<boolean>;
    public get isPublicDiffers$() {
        // if (this.storedFields['public'] && this.storedFields['public']['versionLastUpdatedAt'] !== this.public.versionLastUpdatedAt) {
        //     this._isPublicDiffers$ = null;
        // }

        if (this._isPublicDiffers$ !== undefined) {
            return this._isPublicDiffers$;
        }

        this._isPublicDiffers$ = combineLatest([
            this.modelProvider.script.findByRef(this.ref),
            this.draft$
        ]).pipe(map(([script, draftVersion]) => {
            if (!script.public || !script.public.versionLastUpdatedAt) {
                return false;
            }

            return !(draftVersion.updatedAt.isEqual(script.public.versionLastUpdatedAt));
        })).pipe(shareReplay(1));

        return this._isPublicDiffers$;
    }

    public get isPublished() {
        return (this.public && this.public.active) || false;
    }


    public findAllByWorkspace(workspace: Workspace, query?: QueryFn, showDeleted = false) {
        if (!query) { query = ref => ref; }

        return this.findAllBy(ref => {
            if (showDeleted) {
                return query(ref).where('workspaceRef', '==', workspace.getReference());
            } else {
                return query(ref)
                    .where('active', '==', true)
                    .where('workspaceRef', '==', workspace.getReference());
            }
        });
    }

    protected instantiate(path, data, options?: any) {
        return new Script(path, data, options, this.modelProvider);
    }

    public getStorageReferencePath(category: string, file: File) {
        return this.getDocument().ref.path + '/' + category + '/' + file.name;
    }

    public uploadFile(category: string, file: File, metadata?: any) {
        const reference = this.modelProvider.fsStorage.ref(this.getStorageReferencePath(category, file));
        return {
            ref: reference,
            uploadTask: reference.put(file, metadata)
        };
    }

    async delete() {
        this.active = false;
        return super.save();
    }

    get isTemplate() {
        return this.demo && this.demo.is
    }
    
    getRandomSessionAvailableSlot(role: Role) {
        return (this.analytics && this.analytics.role && this.analytics.role.randomSession && this.analytics.role.randomSession[role.id] && this.analytics.role.randomSession[role.id].available) || 0
    }
}
